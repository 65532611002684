<template>
  {{ animatedText }}
</template>

<script>
export default {
  name: 'TextWritter',
  props: {
    text: {
      type: String,
      required: true
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      animatedText: '',
      timeout: null,
      interval: null,
      currentCharIndex: 0
    }
  },
  methods: {
    typeChar() {
      this.animatedText += this.text[this.currentCharIndex++]

      if (this.currentCharIndex >= this.text.length) {
        clearInterval(this.interval)
      }
    }
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.interval = setInterval(this.typeChar, 50)
    }, this.delay)
  },
  beforeUnmount() {
    clearTimeout(this.timeout)
    clearInterval(this.interval)
  }
}
</script>
