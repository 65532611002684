<template>
  <div :class="`chcc-expo-filter-widget widget-shortby${collapsable ? ' collapsable' : ''}${active || !collapsable ? ' active' : ''}`">
      <div class="inner">
          <div @click="toggleActive" class="widget-title d-flex justify-content-between align-items-center">
              <h5>{{ title }}</h5>
              <i v-if="collapsable" :class="`feather-${active ? 'minus' : 'plus'}`"></i>
          </div>
          <div class="content">
                <div v-if="collapsable" class="search-wrapper">
                    <input type="search" :name="`search-${title}`" :id="`search-${title}`" class="w-100" v-model="search">
                    <i class="feather-search"></i>
                </div>
                <div class="chcc-form-check" v-for="(item, index) in filteredItems" :key="index">
                    <input type="checkbox" :checked="isSelected(item.value)" :value="item.value" :id="`${title}-${index}`" @input="onChange(item.value)">
                    <label :for="`${title}-${index}`" v-html="item.label"></label>
                </div>
          </div>
      </div>
  </div>
</template>

<script>
    export default {
        name: 'ExploreFilterAccordion',
        props: {
            title: {
                type: String,
                required: true
            },
            group: {
                type: String,
                required: true
            },
            items: {
                type: Array,
                required: true
            },
            collapsable: {
                type: Boolean,
                default: true
            },
            selectedItems: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                active: false,
                checkedValues: [],
                search: ''
            }
        },
        computed: {
            filteredItems() {
                return this.items.filter(item =>
                    item.label.toLowerCase().includes(this.search.toLowerCase()))
            }
        },
        methods: {
            toggleActive() {
                this.active = !this.active
                this.search = ''
            },
            onChange(value) {
                if (this.checkedValues.includes(value)) {
                    this.checkedValues = this.checkedValues.filter(v => v != value)
                } else {
                    this.checkedValues.push(value)
                }

                this.$emit('filter-change', this.group, this.checkedValues)
            },
            isSelected(value) {
                return this.selectedItems.includes(value)
            }
        }
    }
</script>

<style>
    .chcc-expo-filter-widget .chcc-form-check {
        position: relative;
    }

    .chcc-expo-filter-widget .content .chcc-form-check label svg {
        color: #dd8f1d;
        margin-right: 4px;
    }

    .chcc-expo-filter-widget .content .chcc-form-check label svg.off {
        color: #5d5d7e;
    }

    .chcc-expo-filter-widget {
        background: var(--background-color-1);
        padding: 20px 30px;
        border-radius: 35px;
        /* border: 1px solid var(--color-border); */
    }

    .chcc-expo-filter-widget .widget-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }

    .chcc-expo-filter-widget .widget-title h5 {
        margin-bottom: 0;
        font-family: 'Brown', sans-serif;
        font-size: 20px;
    }

    .chcc-expo-filter-widget .content .chcc-form-check label {
        font-family: 'Brown', sans-serif;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .chcc-expo-filter-widget input[type=checkbox] ~ label, input[type=radio] ~ label {
        position: relative;
        font-size: 12px;
        line-height: 17px;
        color: var(--color-body);
        font-weight: 400;
        padding-left: 25px;
        cursor: pointer;
    }

    .chcc-expo-filter-widget input[type=checkbox]:checked ~ label::before {
        background-color: var(--color-primary);
    }

    .chcc-expo-filter-widget .content .chcc-form-check + .chcc-form-check {
        margin-top: 15px;
    }

    .chcc-expo-filter-widget .content {
        display: none;
    }

    .chcc-expo-filter-widget.collapsable .widget-title {
        cursor: pointer;
    }

    .chcc-expo-filter-widget.active {
        padding: 20px 30px 30px;
    }

    .chcc-expo-filter-widget.active .content {
        display: block;
    }

    .chcc-expo-filter-widget.active .widget-title {
        /* border-bottom: 1px solid var(--color-border); */
        padding-bottom: 20px;
        /* margin-bottom: 20px; */
    }

    .search-wrapper {
        position: relative;
        margin-bottom: 20px;
    }

    .feather-search {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: gray;
    }

    input[type="search"] {
        border-radius: 100px;
    }

    .search-wrapper input[type="search"]:focus + .feather-search {
        color: lightgray;
    }

    input[type=checkbox] ~ label::after, input[type=radio] ~ label::after {
        border-bottom: 2px solid #22273c!important;
        border-left: 2px solid #22273c!important;
    }

    @media (max-width: 991px) {
        .chcc-expo-filter-widget .content .chcc-form-check label {
            font-size: 14px;
        }
    }
</style>
