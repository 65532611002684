<template>
    <layout>
        <div class="rn-product-area rn-section-gapTop pt--50">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-3 order-1 order-lg-1">
                        <div class="nu-course-sidebar">
                            <explore-filter-accordion
                                v-if="accountAddress"
                                :title="'MY CARDS'"
                                :group="'myCards'"
                                :collapsable="false"
                                :items="filters.myCards"
                                :selected-items="filtering.myCards.values"
                                @filter-change="onFilterChange"
                            />
                            <!-- <explore-filter-accordion
                                class="mt--30"
                                :title="'TITLE'"
                                :group="'title'"
                                :collapsable="true"
                                :items="filters.title"
                                :selected-items="filtering.title.values"
                                @filter-change="onFilterChange"
                            /> -->
                            <explore-filter-accordion
                                :class="{'mt--30': accountAddress}"
                                :title="'ANIMATED'"
                                :group="'animated'"
                                :collapsable="false"
                                :items="filters.animated"
                                :selected-items="filtering.animated.values"
                                @filter-change="onFilterChange"
                            />
                            <explore-filter-accordion
                                class="mt--30"
                                :title="'CATEGORY'"
                                :group="'category'"
                                :collapsable="false"
                                :items="filters.category"
                                :selected-items="filtering.category.values"
                                @filter-change="onFilterChange"
                            />
                            <explore-filter-accordion
                                class="mt--30"
                                :title="'ARTIST'"
                                :group="'artist'"
                                :collapsable="true"
                                :items="filters.artist"
                                :selected-items="filtering.artist.values"
                                @filter-change="onFilterChange"
                            />
                            <!-- <explore-filter-accordion
                                class="mt--30"
                                :title="'EDITION SIZE'"
                                :group="'editionSize'"
                                :collapsable="false"
                                :items="filters.editionSize"
                                :selected-items="filtering.editionSize.values"
                                @filter-change="onFilterChange"
                            /> -->
                            <explore-filter-accordion
                                class="mt--30"
                                :title="'VARIANT'"
                                :group="'variant'"
                                :collapsable="true"
                                :items="filters.variant"
                                :selected-items="filtering.variant.values"
                                @filter-change="onFilterChange"
                            />
                            <!-- <explore-filter-accordion
                                class="mt--30"
                                :title="'RARITY'"
                                :group="'rarity'"
                                :collapsable="false"
                                :items="filters.rarity"
                                :selected-items="filtering.rarity.values"
                                @filter-change="onFilterChange"
                            /> -->
                            <!-- <explore-filter-accordion
                                class="mt--30"
                                :title="'STARS'"
                                :group="'stars'"
                                :collapsable="false"
                                :items="filters.stars"
                                :selected-items="filtering.stars.values"
                                @filter-change="onFilterChange"
                            /> -->
                        </div>
                    </div>
                    <div ref="tokenList" class="col-lg-9 order-2 order-lg-2">
                        <h3 class="total-tokens mb--30">
                            {{ totalItems }} CARD{{ totalItems == 1 ? '' : 'S' }}
                        </h3>
                        <div class="tab-wrapper-one">
                            <nav class="tab-button-one">
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button @click="handleNavLinkFilter('')"
                                            :class="`nav-link${rarityFilter ? '' : ' active'}`"
                                            id="nav-all-cards-tab"
                                            type="button" role="tab"
                                            aria-controls="nav-all-cards"
                                            aria-selected="false">
                                        All Cards
                                    </button>
                                    <button @click="handleNavLinkFilter('Common')"
                                            :class="`nav-link${rarityFilter == 'Common' ? ' active' : ''}`"
                                            id="nav-common-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-common"
                                            aria-selected="false">
                                        Common
                                    </button>
                                    <button @click="handleNavLinkFilter('Rare')"
                                            :class="`nav-link${rarityFilter == 'Rare' ? ' active' : ''}`"
                                            id="nav-rare-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-rare"
                                            aria-selected="false">
                                        Rare
                                    </button>
                                    <button @click="handleNavLinkFilter('Legendary')"
                                            :class="`nav-link${rarityFilter == 'Legendary' ? ' active' : ''}`"
                                            id="nav-legendary-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-legendary"
                                            aria-selected="false">
                                        Legendary
                                    </button>
                                    <button @click="handleNavLinkFilter('Mythic')"
                                            :class="`nav-link${rarityFilter == 'Mythic' ? ' active' : ''}`"
                                            id="nav-mythic-tab"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-mythic"
                                            aria-selected="false">
                                        Mythic
                                    </button>
                                </div>
                            </nav>
                        </div>
                        <div v-if="isFiltering" class="filter-badges">
                            <template v-for="(filter, group) in filtering" :key="filter">
                                <template v-if="group != 'rarity'">
                                    <FilterBadge v-for="value in filter.values" :key="value" :group="group" :value="value" @close="uncheckFilter">
                                        {{ filter.label }}: {{ value }}
                                    </FilterBadge>
                                </template>
                            </template>
                        </div>
                        <div id="tokens-section" class="row g-5">
                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"
                                v-for="(item, index) in tokens"
                                :key="`newest-item-${index}`">
                                <token-card
                                    :token-data="item"
                                    @badge-click="handleBadgeClick"
                                    token-style-class="no-overlay"
                                />
                            </div>
                        </div>
                        <div v-if="!loading && !tokens.length" class="mt--50 text-center">
                            <svg style="width: 50px; height: 50px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-frown"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                            <div class="mt--10">No tokens found for this filter.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import { mapState } from 'vuex'
    import TokenCard from '@/components/token/TokenCard'
    import ExploreFilterAccordion from '@/components/explore/ExploreFilterAccordion'
    import FilterBadge from '@/components/misc/FilterBadge'
    import Layout from '@/components/layouts/Layout'
    import http from '@/services/httpService'

    export default {
        name: 'Gallery',
        components: {Layout, TokenCard, ExploreFilterAccordion, FilterBadge},
        data() {
            return {
                filtering: {
                    myCards: {
                        label: 'My Cards',
                        values: []
                    },
                    title: {
                        label: 'Title',
                        values: []
                    },
                    animated: {
                        label: 'Animated',
                        values: []
                    },
                    artist: {
                        label: 'Artist',
                        values: []
                    },
                    editionSize: {
                        label: 'Edition Size',
                        values: []
                    },
                    category: {
                        label: 'Category',
                        values: []
                    },
                    variant: {
                        label: 'Variant',
                        values: []
                    },
                    rarity: {
                        label: 'Rarity',
                        values: []
                    },
                    stars: {
                        label: 'Stars',
                        values: []
                    }
                },
                filters: {
                    myCards: [
                        { label: 'Owned', value: 'Owned' },
                        { label: 'Not owned', value: 'Not owned' },
                    ],
                    title: [
                        { label: 'Title 1', value: 'Title 1' },
                        { label: 'Title 2', value: 'Title 2' },
                        { label: 'Title 3', value: 'Title 3' },
                    ],
                    animated: [
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' }
                    ],
                    artist: [
                        { label: 'Aaron Luhung', value: 'Aaron Luhung' },
                        { label: 'AilaSpiritBox', value: 'AilaSpiritBox' },
                        { label: 'Ajay Ahdiyat', value: 'Ajay Ahdiyat' },
                        { label: 'Albert Soloveiv', value: 'Albert Soloveiv' },
                        { label: 'Alfraea', value: 'Alfraea' },
                        { label: 'Alterlier', value: 'Alterlier' },
                        { label: 'AnaNFT', value: 'AnaNFT' },
                        { label: 'Antoine Doré', value: 'Antoine Doré' },
                        { label: 'Anton Marrast', value: 'Anton Marrast' },
                        { label: 'Anya Kuzai', value: 'Anya Kuzai' },
                        { label: 'ArtFlame', value: 'ArtFlame' },
                        { label: 'Artmorfate', value: 'Artmorfate' },
                        { label: 'Aryan Khani', value: 'Aryan Khani' },
                        { label: 'Aswang', value: 'Aswang' },
                        { label: 'Avenue', value: 'Avenue' },
                        { label: 'Ayaka Ohira', value: 'Ayaka Ohira' },
                        { label: 'Ayrusart', value: 'Ayrusart' },
                        { label: 'B Clarissa', value: 'B Clarissa' },
                        { label: 'Bad Bird', value: 'Bad Bird' },
                        { label: 'BandageNFT', value: 'BandageNFT' },
                        { label: 'Baroeki', value: 'Baroeki' },
                        { label: 'Ben Bauchau', value: 'Ben Bauchau' },
                        { label: 'Benangbaja', value: 'Benangbaja' },
                        { label: 'Cappie', value: 'Cappie' },
                        { label: 'Carbine', value: 'Carbine' },
                        { label: 'Ch=mpathy', value: 'Ch=mpathy' },
                        { label: 'CharNim', value: 'CharNim' },
                        { label: 'Chelsy Escalona', value: 'Chelsy Escalona' },
                        { label: 'Chiara', value: 'Chiara' },
                        { label: 'Chris Alliel', value: 'Chris Alliel' },
                        { label: 'Christy', value: 'Christy' },
                        { label: 'Clagri', value: 'Clagri' },
                        { label: 'D E O', value: 'D E O' },
                        { label: 'Dalek', value: 'Dalek' },
                        { label: 'Davey Perkins', value: 'Davey Perkins' },
                        { label: 'Deviant', value: 'Deviant' },
                        { label: 'Diberkato', value: 'Diberkato' },
                        { label: 'Donstellaarte', value: 'Donstellaarte' },
                        { label: 'Dustyrose', value: 'Dustyrose' },
                        { label: 'EKTZ', value: 'EKTZ' },
                        { label: 'EYEGIRL', value: 'EYEGIRL' },
                        { label: 'Eloh', value: 'Eloh' },
                        { label: 'Eri Taopik', value: 'Eri Taopik' },
                        { label: 'Eszter Lakatos', value: 'Eszter Lakatos' },
                        { label: 'Fahmi Fauzi', value: 'Fahmi Fauzi' },
                        { label: 'Farnaaz', value: 'Farnaaz' },
                        { label: 'Feilipu', value: 'Feilipu' },
                        { label: 'Fiketor', value: 'Fiketor' },
                        { label: 'Florets', value: 'Florets' },
                        { label: 'Fobpo', value: 'Fobpo' },
                        { label: 'FoksyNes', value: 'FoksyNes' },
                        { label: 'Galang', value: 'Galang' },
                        { label: 'Geno Genma', value: 'Geno Genma' },
                        { label: 'Gilen', value: 'Gilen' },
                        { label: 'GoldenKwang', value: 'GoldenKwang' },
                        { label: 'Graves', value: 'Graves' },
                        { label: 'Guitarana', value: 'Guitarana' },
                        { label: 'Habiba Green', value: 'Habiba Green' },
                        { label: 'HanaanDesu', value: 'HanaanDesu' },
                        { label: 'Happy Monday', value: 'Happy Monday' },
                        { label: 'Harry Fitriansyah', value: 'Harry Fitriansyah' },
                        { label: 'Haru', value: 'Haru' },
                        { label: 'Harudai Chachir', value: 'Harudai Chachir' },
                        { label: 'Hasan Göktepe', value: 'Hasan Göktepe' },
                        { label: 'Hex', value: 'Hex' },
                        { label: 'Hikeru Yu', value: 'Hikeru Yu' },
                        { label: 'Ian Barreto', value: 'Ian Barreto' },
                        { label: 'Iannocent', value: 'Iannocent' },
                        { label: 'Ichiren', value: 'Ichiren' },
                        { label: 'Ijur', value: 'Ijur' },
                        { label: 'Illy Kostova', value: 'Illy Kostova' },
                        { label: 'Jarwoe', value: 'Jarwoe' },
                        { label: 'Jasumin', value: 'Jasumin' },
                        { label: 'Jose Canizares', value: 'Jose Canizares' },
                        { label: 'Julia Uffizi', value: 'Julia Uffizi' },
                        { label: 'June', value: 'June' },
                        { label: 'KAE', value: 'KAE' },
                        { label: 'KIV BUI', value: 'KIV BUI' },
                        { label: 'Kaejunni', value: 'Kaejunni' },
                        { label: 'Kanashi Hito', value: 'Kanashi Hito' },
                        { label: 'Karl Trewhela', value: 'Karl Trewhela' },
                        { label: 'Kazadi', value: 'Kazadi' },
                        { label: 'Kevin Varian', value: 'Kevin Varian' },
                        { label: 'Kitsu', value: 'Kitsu' },
                        { label: 'Kolahon', value: 'Kolahon' },
                        { label: 'KucingKecil', value: 'KucingKecil' },
                        { label: 'Kyokimaru', value: 'Kyokimaru' },
                        { label: 'Lady Yin', value: 'Lady Yin' },
                        { label: 'Lamenthyst', value: 'Lamenthyst' },
                        { label: 'Lewdlily', value: 'Lewdlily' },
                        { label: 'LightenBee', value: 'LightenBee' },
                        { label: 'Little Lis', value: 'Little Lis' },
                        { label: 'LoeChii', value: 'LoeChii' },
                        { label: 'LoreDangoo', value: 'LoreDangoo' },
                        { label: 'Loulan', value: 'Loulan' },
                        { label: 'Loyalty Park', value: 'Loyalty Park' },
                        { label: 'MACO', value: 'MACO' },
                        { label: 'Mannay', value: 'Mannay' },
                        { label: 'Marcello Castellino', value: 'Marcello Castellino' },
                        { label: 'Markus Manson', value: 'Markus Manson' },
                        { label: 'Mat Miller', value: 'Mat Miller' },
                        { label: 'Mayuri', value: 'Mayuri' },
                        { label: 'Mellow', value: 'Mellow' },
                        { label: 'Michael Rusakov', value: 'Michael Rusakov' },
                        { label: 'MiniMjm', value: 'MiniMjm' },
                        { label: 'Mochii', value: 'Mochii' },
                        { label: 'MoldyRabbit', value: 'MoldyRabbit' },
                        { label: 'MonStreet', value: 'MonStreet' },
                        { label: 'Moon', value: 'Moon' },
                        { label: 'MoonAbsorb', value: 'MoonAbsorb' },
                        { label: 'Mr Lakon', value: 'Mr Lakon' },
                        { label: 'NP Russell', value: 'NP Russell' },
                        { label: 'NaNinna', value: 'NaNinna' },
                        { label: 'Naime Pakniyat', value: 'Naime Pakniyat' },
                        { label: 'Neogreenmonster', value: 'Neogreenmonster' },
                        { label: 'Niazi', value: 'Niazi' },
                        { label: 'Noche Niche', value: 'Noche Niche' },
                        { label: 'Ohne', value: 'Ohne' },
                        { label: 'Orabel', value: 'Orabel' },
                        { label: 'Paletear', value: 'Paletear' },
                        { label: 'Patsi', value: 'Patsi' },
                        { label: 'Pawwao', value: 'Pawwao' },
                        { label: 'Penyukataa', value: 'Penyukataa' },
                        { label: 'Peter Mohrbacher', value: 'Peter Mohrbacher' },
                        { label: 'Piumeli', value: 'Piumeli' },
                        { label: 'Purplx', value: 'Purplx' },
                        { label: 'Qay', value: 'Qay' },
                        { label: 'Queena', value: 'Queena' },
                        { label: 'Reflex', value: 'Reflex' },
                        { label: 'Rensi Ardinta', value: 'Rensi Ardinta' },
                        { label: 'Rexndeer', value: 'Rexndeer' },
                        { label: 'RinaRink', value: 'RinaRink' },
                        { label: 'Risa', value: 'Risa' },
                        { label: 'Rolo Mallada', value: 'Rolo Mallada' },
                        { label: 'Ronan Hunt', value: 'Ronan Hunt' },
                        { label: 'Rukasu', value: 'Rukasu' },
                        { label: 'Rykyart', value: 'Rykyart' },
                        { label: 'Sammy Lewis', value: 'Sammy Lewis' },
                        { label: 'Sathar', value: 'Sathar' },
                        { label: 'Seek Goliath', value: 'Seek Goliath' },
                        { label: 'Seerlight', value: 'Seerlight' },
                        { label: 'Sommimi', value: 'Sommimi' },
                        { label: 'Somonicav', value: 'Somonicav' },
                        { label: 'Stardrugg', value: 'Stardrugg' },
                        { label: 'Staticfabercut', value: 'Staticfabercut' },
                        { label: 'Suki Zweet', value: 'Suki Zweet' },
                        { label: 'SweeneyPen', value: 'SweeneyPen' },
                        { label: 'Swheatz', value: 'Swheatz' },
                        { label: 'TNK', value: 'TNK' },
                        { label: 'Taylor C Adams', value: 'Taylor C Adams' },
                        { label: 'The Hiena', value: 'The Hiena' },
                        { label: 'TheHiddenWalls', value: 'TheHiddenWalls' },
                        { label: 'TheRafa_Arts', value: 'TheRafa_Arts' },
                        { label: 'TinWorks', value: 'TinWorks' },
                        { label: 'Tito Merello', value: 'Tito Merello' },
                        { label: 'Tue Tue', value: 'Tue Tue' },
                        { label: 'UMI', value: 'UMI' },
                        { label: 'URI', value: 'URI' },
                        { label: 'ValyaNarts', value: 'ValyaNarts' },
                        { label: 'Vhox', value: 'Vhox' },
                        { label: 'Vidar Francke', value: 'Vidar Francke' },
                        { label: 'Vierlera', value: 'Vierlera' },
                        { label: 'Xsullo', value: 'Xsullo' },
                        { label: 'Yana', value: 'Yana' },
                        { label: 'Yiw_Artlab_Nft', value: 'Yiw_Artlab_Nft' },
                        { label: 'Zetsurin', value: 'Zetsurin' },
                        { label: '???', value: '???' },
                    ],
                    editionSize: [
                        { label: '5', value: '5' },
                        { label: '10', value: '10' },
                        { label: '25', value: '25' },
                        { label: '50', value: '50' },
                        { label: '125', value: '125' },
                    ],
                    category: [
                        { label: 'Demi-Human', value: 'Demi-Human' },
                        { label: 'Human', value: 'Human' },
                        { label: 'Posthuman', value: 'Posthuman' },
                        { label: 'Storyline', value: 'Storyline' },
                        { label: 'Yokai', value: 'Yokai' },
                        { label: 'Zodiac', value: 'Zodiac' },
                    ],
                    variant: [
                        { label: 'Agent', value: 'Agent' },
                        { label: 'Ancient Captain', value: 'Ancient Captain' },
                        { label: 'Ancient General', value: 'Ancient General' },
                        { label: 'Ancient Maid', value: 'Ancient Maid' },
                        { label: 'Ancient Prince', value: 'Ancient Prince' },
                        { label: 'Ancient Servant', value: 'Ancient Servant' },
                        { label: 'Angler Fish', value: 'Angler Fish' },
                        { label: 'Ape', value: 'Ape' },
                        { label: 'Aquarius', value: 'Aquarius' },
                        { label: 'Aries', value: 'Aries' },
                        { label: 'Assassin', value: 'Assassin' },
                        { label: 'Autumn Student', value: 'Autumn Student' },
                        { label: 'Axe Warden', value: 'Axe Warden' },
                        { label: 'Axolotl', value: 'Axolotl' },
                        { label: 'Bandit', value: 'Bandit' },
                        { label: 'Barbarian', value: 'Barbarian' },
                        { label: 'Baseball Player', value: 'Baseball Player' },
                        { label: 'Bear', value: 'Bear' },
                        { label: 'Bitcoin Cyborg', value: 'Bitcoin Cyborg' },
                        { label: 'Block Party', value: 'Block Party' },
                        { label: 'Boxer', value: 'Boxer' },
                        { label: 'Bright Lights', value: 'Bright Lights' },
                        { label: 'Broadsword Vangaurd', value: 'Broadsword Vangaurd' },
                        { label: 'Bull', value: 'Bull' },
                        { label: 'Bumblebee', value: 'Bumblebee' },
                        { label: 'Cancer', value: 'Cancer' },
                        { label: 'Capricorn', value: 'Capricorn' },
                        { label: 'Cat Cosplayer', value: 'Cat Cosplayer' },
                        { label: 'Cow', value: 'Cow' },
                        { label: 'Crab', value: 'Crab' },
                        { label: 'Cthylla', value: 'Cthylla' },
                        { label: 'Cultist', value: 'Cultist' },
                        { label: 'Cyber Samurai', value: 'Cyber Samurai' },
                        { label: 'DJ', value: 'DJ' },
                        { label: 'Dark Spirit', value: 'Dark Spirit' },
                        { label: 'Dragon', value: 'Dragon' },
                        { label: 'Earth Spirit', value: 'Earth Spirit' },
                        { label: 'End Game', value: 'End Game' },
                        { label: 'Ethereum Cyborg', value: 'Ethereum Cyborg' },
                        { label: 'Evil Clown', value: 'Evil Clown' },
                        { label: 'Fire Spirit', value: 'Fire Spirit' },
                        { label: 'Futbol player', value: 'Futbol player' },
                        { label: 'Gemini', value: 'Gemini' },
                        { label: 'Genesis Ritual', value: 'Genesis Ritual' },
                        { label: 'Goat', value: 'Goat' },
                        { label: 'Great White Shark', value: 'Great White Shark' },
                        { label: 'Gynoid', value: 'Gynoid' },
                        { label: 'Hacker', value: 'Hacker' },
                        { label: 'Hammer Paladin', value: 'Hammer Paladin' },
                        { label: 'Hammerhead Shark', value: 'Hammerhead Shark' },
                        { label: 'Heckler', value: 'Heckler' },
                        { label: 'Hitotsume-Kozo', value: 'Hitotsume-Kozo' },
                        { label: 'Horse', value: 'Horse' },
                        { label: 'Host', value: 'Host' },
                        { label: 'House Cat', value: 'House Cat' },
                        { label: 'Ibaraki-Doji', value: 'Ibaraki-Doji' },
                        { label: 'Ice Spirit', value: 'Ice Spirit' },
                        { label: 'Infiltrator', value: 'Infiltrator' },
                        { label: 'Jellyfish', value: 'Jellyfish' },
                        { label: 'Kappa', value: 'Kappa' },
                        { label: 'Kappa (rare)', value: 'Kappa (rare)' },
                        { label: 'Kijo', value: 'Kijo' },
                        { label: 'Kimono Model', value: 'Kimono Model' },
                        { label: 'Kitsune', value: 'Kitsune' },
                        { label: 'Komainu', value: 'Komainu' },
                        { label: 'Kunoichi', value: 'Kunoichi' },
                        { label: 'Kuro-Bozu', value: 'Kuro-Bozu' },
                        { label: 'Lance Defender', value: 'Lance Defender' },
                        { label: 'Last Flight', value: 'Last Flight' },
                        { label: 'Lazy Boy', value: 'Lazy Boy' },
                        { label: 'Leo', value: 'Leo' },
                        { label: 'Libra', value: 'Libra' },
                        { label: 'Lion', value: 'Lion' },
                        { label: 'Liquidator', value: 'Liquidator' },
                        { label: 'Medic', value: 'Medic' },
                        { label: 'Medlab', value: 'Medlab' },
                        { label: 'Megalodon', value: 'Megalodon' },
                        { label: 'Mermaid', value: 'Mermaid' },
                        { label: 'Mysterious Doll', value: 'Mysterious Doll' },
                        { label: 'Nightmare', value: 'Nightmare' },
                        { label: 'Nurarihyon', value: 'Nurarihyon' },
                        { label: 'Octopus', value: 'Octopus' },
                        { label: 'Officer', value: 'Officer' },
                        { label: 'Oni', value: 'Oni' },
                        { label: 'Onryo', value: 'Onryo' },
                        { label: 'Orca', value: 'Orca' },
                        { label: 'Ox', value: 'Ox' },
                        { label: 'Phantom Nun', value: 'Phantom Nun' },
                        { label: 'Pilot', value: 'Pilot' },
                        { label: 'Pirate', value: 'Pirate' },
                        { label: 'Pisces', value: 'Pisces' },
                        { label: 'Rabbit', value: 'Rabbit' },
                        { label: 'Rapier Mercenary', value: 'Rapier Mercenary' },
                        { label: 'Rat', value: 'Rat' },
                        { label: 'Researcher', value: 'Researcher' },
                        { label: 'Rokurokuni', value: 'Rokurokuni' },
                        { label: 'Ronin of the Valley', value: 'Ronin of the Valley' },
                        { label: 'Rugby Player', value: 'Rugby Player' },
                        { label: 'Sabertooth Tiger', value: 'Sabertooth Tiger' },
                        { label: 'Sagittarius', value: 'Sagittarius' },
                        { label: 'Scorpio', value: 'Scorpio' },
                        { label: 'Seahorse', value: 'Seahorse' },
                        { label: 'Sentinel', value: 'Sentinel' },
                        { label: 'Shikigami', value: 'Shikigami' },
                        { label: 'Shiro Ukari', value: 'Shiro Ukari' },
                        { label: 'Simulacrum', value: 'Simulacrum' },
                        { label: 'Snake', value: 'Snake' },
                        { label: 'Spear Warrior', value: 'Spear Warrior' },
                        { label: 'Spider', value: 'Spider' },
                        { label: 'Spinosaurus', value: 'Spinosaurus' },
                        { label: 'Spirit', value: 'Spirit' },
                        { label: 'Spirit Detective', value: 'Spirit Detective' },
                        { label: 'Spiritual Ferry Ride', value: 'Spiritual Ferry Ride' },
                        { label: 'Spring Model', value: 'Spring Model' },
                        { label: 'Spy', value: 'Spy' },
                        { label: 'Squid', value: 'Squid' },
                        { label: 'Summer Model', value: 'Summer Model' },
                        { label: 'Summer Student', value: 'Summer Student' },
                        { label: 'Sun Shower', value: 'Sun Shower' },
                        { label: 'Swimmer', value: 'Swimmer' },
                        { label: 'Swordfish', value: 'Swordfish' },
                        { label: 'Taurus', value: 'Taurus' },
                        { label: 'Taurus Mosaic', value: 'Taurus Mosaic' },
                        { label: 'Technician', value: 'Technician' },
                        { label: 'Temple of Sirens', value: 'Temple of Sirens' },
                        { label: 'Tengu', value: 'Tengu' },
                        { label: 'Tennis Player', value: 'Tennis Player' },
                        { label: 'Terrorbird', value: 'Terrorbird' },
                        { label: 'Tiger Shark', value: 'Tiger Shark' },
                        { label: 'Tonfa Guard', value: 'Tonfa Guard' },
                        { label: 'Tortoise', value: 'Tortoise' },
                        { label: 'Transposed Apex', value: 'Transposed Apex' },
                        { label: 'Triceratops', value: 'Triceratops' },
                        { label: 'Turtle', value: 'Turtle' },
                        { label: 'Tyrannosaurus Rex', value: 'Tyrannosaurus Rex' },
                        { label: 'Virgo', value: 'Virgo' },
                        { label: 'Volleyball Player', value: 'Volleyball Player' },
                        { label: 'Wanyudo', value: 'Wanyudo' },
                        { label: 'Water Spirit', value: 'Water Spirit' },
                        { label: 'Whale Shark', value: 'Whale Shark' },
                        { label: 'Wildcat', value: 'Wildcat' },
                        { label: 'Winter Model', value: 'Winter Model' },
                        { label: 'Winter Student', value: 'Winter Student' },
                        { label: 'Yokai', value: 'Yokai' },
                        { label: 'Yokai\'s Moving Castle', value: 'Yokai\'s Moving Castle' },
                        { label: 'Yuki-Onna', value: 'Yuki-Onna' },
                        { label: 'Zashiki-Waraski', value: 'Zashiki-Waraski' },
                    ],
                    rarity: [
                        { label: 'Common', value: 'Common' },
                        { label: 'Rare', value: 'Rare' },
                        { label: 'Legendary', value: 'Legendary' },
                        { label: 'Mythic', value: 'Mythic' },
                    ],
                    stars: [
                        { label: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>`, value: '4' },
                        { label: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill off" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>`, value: '3' },
                        { label: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill off" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill off" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>`, value: '2' },
                        { label: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill off" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill off" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill off" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                    </svg>`, value: '1' },
                    ]
                },
                loading: true,
                tokens: [],
                currentPage: 1,
                perPage: 16,
                totalPages: 0,
                totalItems: 0
            }
        },
        computed: {
            ...mapState([
                'accountAddress'
            ]),
            isFiltering() {
                for (const group in this.filtering) {
                    if (group != 'rarity' && this.filtering[group].values.length > 0) {
                        return true
                    }
                }

                return false
            },
            rarityFilter() {
                return this.filtering.rarity.values[0]
            }
        },
        watch: {
            accountAddress() {
                this.tokens = []
                this.currentPage = 1
                this.fetchTokens()
            }
        },
        methods: {
            fetchTokens() {
                this.loading = true
                const filters = {}

                for (const attribute in this.filtering) {
                    if (attribute != 'myCards') {
                        filters[attribute] = this.filtering[attribute].values
                    }
                }

                if (this.filtering.myCards.values.length > 0) {
                    filters.owned = Math.floor(this.filtering.myCards.values[0] == 'Owned')
                    filters.account = this.accountAddress
                }

                http.get(`/tokens?page=${this.currentPage}&perPage=${this.perPage}&${new URLSearchParams(filters)}`)
                    .then(response => {
                        if (response.data.items[0]?.tokenId != this.tokens[0]?.tokenId) {
                            this.tokens.push(...response.data.items)
                        }

                        this.totalPages = response.data.totalPages
                        this.totalItems = response.data.totalItems
                    })
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            onFilterChange(group, values) {
                if (group == 'myCards' && values.length > 1) {
                    this.filtering.myCards.values.splice(0, 1)
                } else if (group == 'animated' && values.length > 1) {
                    this.filtering.animated.values.splice(0, 1)
                }

                this.filtering[group].values = values
                this.tokens = []
                this.currentPage = 1
                this.fetchTokens()

                window.scrollTo({
                    top: this.$refs.tokenList.offsetTop - (window.innerWidth >= 1200 ? 96 : 30),
                    behavior: 'smooth'
                })
            },
            uncheckFilter(group, value) {
                const index = this.filtering[group].values.indexOf(value)
                this.filtering[group].values.splice(index, 1)
                this.tokens = []
                this.currentPage = 1
                this.fetchTokens()
            },
            handleNavLinkFilter(rarity) {
                if (this.rarityFilter == rarity) {
                    return
                }

                if (rarity) {
                    this.filtering.rarity.values = [rarity]
                } else {
                    this.filtering.rarity.values = []
                }

                this.tokens = []
                this.currentPage = 1
                this.fetchTokens()
            },
            handleBadgeClick(group, value) {
                this.resetFilter()
                this.filtering[group].values = [value]
                this.tokens = []
                this.currentPage = 1
                this.fetchTokens()
            },
            resetFilter() {
                for (const group in this.filtering) {
                    for (const value of this.filtering[group].values) {
                        const index = this.filtering[group].values.indexOf(value)
                        this.filtering[group].values.splice(index, 1)
                    }
                }
            },
            onWindowScroll(e) {
                if ((window.innerHeight + window.scrollY) >= (document.getElementById('tokens-section').offsetHeight - 100) && !this.loading && this.currentPage < this.totalPages) {
                    this.currentPage++
                    this.fetchTokens()
                }
            }
        },
        mounted() {
            window.addEventListener('scroll', this.onWindowScroll)
            const attributeFilter = JSON.parse(window.sessionStorage.getItem('ATTRIBUTE_FILTER'))

            if (attributeFilter) {
                const { group, value } = attributeFilter
                this.onFilterChange(group, [value])
                window.sessionStorage.removeItem('ATTRIBUTE_FILTER')
            } else {
                this.fetchTokens()
            }
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.onWindowScroll)
        }
    }
</script>

<style scoped>
    #nav-all-cards-tab:focus {
        border-color: #ffffff!important;
    }

    #nav-common-tab {
        color: #FFFFFF;
    }

    #nav-common-tab:focus {
        border-color: #FFFFFF!important;
    }

    #nav-common-tab.active {
        color: black;
        background-color: #FFFFFF;
    }

    #nav-rare-tab {
        color: #FFD700;
    }

    #nav-rare-tab:focus {
        border-color: #FFD700!important;
    }

    #nav-rare-tab.active {
        color: black;
        background-color: #FFD700;
    }

    #nav-legendary-tab {
        color: #00BF94;
    }

    #nav-legendary-tab:focus {
        border-color: #00BF94!important;
    }

    #nav-legendary-tab.active {
        color: black;
        background-color: #00BF94;
    }

    #nav-mythic-tab {
        color: #FF4ABD;
    }

    #nav-mythic-tab:focus {
        border-color: #FF4ABD!important;
    }

    #nav-mythic-tab.active {
        color: black;
        background-color: #FF4ABD;
    }

    .tab-button-one .nav-tabs,
    .tab-button-one .nav-tabs button {
        border-radius: 35px!important;
    }

    .tab-button-one .nav-tabs {
        border: 0;
    }

    .tab-button-one .nav-tabs .nav-link.active {
        pointer-events: none;
    }

    .filter-badges {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        gap: 10px;
    }

    .total-tokens {
        font-family: 'Brown', sans-serif;
        font-size: 30px;
    }
</style>
