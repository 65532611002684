<template>
    <Layout>
        <div v-if="!checkingPack" :class="{'video-wrapper': true, playing}">
            <svg class="anchors" viewBox="0 0 1000 563" xmlns="http://www.w3.org/2000/svg">
                <foreignObject x="0" y="0" width="100%" height="100%">
                    <video xmlns="http://www.w3.org/1999/xhtml" muted playsinline loop autoplay @loadeddata="onVideoLoaded">
                        <source :src="videoUrl" type="video/mp4" />
                    </video>
                </foreignObject>
                <template v-if="playing">
                    <a xlink:href="#">
                        <rect x="94" y="113" width="118" height="85" fill="transparent" />
                    </a>
                    <a xlink:href="https://medium.com/@capsulehouselore" target="_blank">
                        <rect x="271" y="112" width="72" height="106" fill="transparent" />
                    </a>
                    <a xlink:href="https://opensea.io/collection/capsule-zodiac-cards" target="_blank">
                        <rect x="715" y="102" width="36" height="55" fill="transparent" transform="rotate(-2 0 330)" />
                    </a>
                    <a xlink:href="https://opensea.io/collection/zodiac-capsules" target="_blank">
                        <rect x="755" y="76" width="74" height="50" fill="transparent" />
                    </a>
                    <a xlink:href="https://opensea.io/collection/capsule-community-curated" target="_blank">
                        <rect x="840" y="78" width="54" height="70" fill="transparent" />
                    </a>
                    <a xlink:href="https://opensea.io/collection/capsulehouse" target="_blank">
                        <rect x="901" y="167" width="60" height="72" fill="transparent" />
                    </a>
                    <a xlink:href="https://cards.capsulehouse.io/burn" target="_blank">
                        <rect x="688" y="154" width="145" height="72" fill="transparent" class="chestAnchor" />
                    </a>
                    <text x="240" y="380" fill="white" style="font-family: 'Brown', sans-serif;">
                        <tspan x="240" y="380">
                            <TextWritter text="Welcome to the Cursed Kiseru," :delay="0" />
                        </tspan>
                        <tspan x="240" y="400">
                            <TextWritter text="traveler. Browse my wares and" :delay="1410" />
                        </tspan>
                        <tspan x="240" y="420">
                            <TextWritter text="find what fancies you. Cards?" :delay="2900" />
                        </tspan>
                        <tspan x="240" y="440">
                            <TextWritter text="Rare artifacts? Check back" :delay="4300" />
                        </tspan>
                        <tspan x="240" y="460">
                            <TextWritter text="often, you never know what" :delay="5600" />
                        </tspan>
                        <tspan x="240" y="480">
                            <TextWritter text="I may have in stock." :delay="6900" />
                        </tspan>
                    </text>
                    <image :href="require('@/assets/images/icons/external-link.svg')" width="10" height="10" fill="white" x="687" y="378" />
                    <image :href="require('@/assets/images/icons/external-link.svg')" width="10" height="10" fill="white" x="660" y="395" />
                    <image :href="require('@/assets/images/icons/external-link.svg')" width="10" height="10" fill="white" x="668" y="412" />
                    <image :href="require('@/assets/images/icons/external-link.svg')" width="10" height="10" fill="white" x="693" y="429" />
                    <image :href="require('@/assets/images/icons/external-link.svg')" width="10" height="10" fill="white" x="687" y="446" />
                    <image :href="require('@/assets/images/icons/external-link.svg')" width="10" height="10" fill="white" x="685" y="463" />
                    <image :href="require('@/assets/images/icons/external-link.svg')" width="10" height="10" fill="white" x="710" y="480" />
                    <text class="links" x="590" y="390" fill="white" style="font-family: 'Brown', sans-serif; font-size: 12px;">
                        <tspan x="590" y="368" style="font-weight: 700;">
                            Beyond the Shop:
                        </tspan>
                        <tspan @click="openLink('https://medium.com/@capsulehouselore')" x="590" y="388">
                            Tale of the Kitsune&nbsp;&nbsp;&nbsp;
                        </tspan>
                        <tspan @click="openLink('https://opensea.io/collection/capsulehouse')" x="590" y="405">
                            Capsule House&nbsp;&nbsp;&nbsp;
                        </tspan>
                        <tspan @click="openLink(' https://opensea.io/collection/zodiac-capsules')" x="590" y="422">
                            Zodiac Capsules&nbsp;&nbsp;&nbsp;
                        </tspan>
                        <tspan @click="openLink('https://opensea.io/collection/capsule-zodiac-cards')" x="590" y="439">
                            Capsule Zodiac Cards&nbsp;&nbsp;&nbsp;
                        </tspan>
                        <tspan @click="openLink('https://opensea.io/collection/capsule-community-curated')" x="590" y="456">
                            Community Curated&nbsp;&nbsp;&nbsp;
                        </tspan>
                        <tspan @click="openLink('https://opensea.io/collection/chcc-genesis-chest')" x="590" y="473">
                            CHCC: Genesis Chest&nbsp;&nbsp;&nbsp;
                        </tspan>
                        <tspan @click="openLink('https://opensea.io/collection/chcc-genesis-collection')" x="590" y="490">
                            CHCC: Genesis Collection&nbsp;&nbsp;&nbsp;&nbsp;
                        </tspan>
                    </text>
                </template>
            </svg>
        </div>
    </Layout>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    // import packContractService from '@/services/packContractService'
    import Layout from '@/components/layouts/Layout'
    import TextWritter from '@/components/misc/TextWritter'

    export default {
        name: 'Shop',
        components: {Layout, TextWritter},
        data() {
            return {
                playing: false,
                checkingPack: false
            }
        },
        computed: {
            ...mapState([
                'authCheck',
                'accountAddress'
            ]),
            ...mapGetters([
                'isValidNetwork'
            ]),
            videoUrl() {
                return `${process.env.VUE_APP_API_URL}/videos/burn-chest-background.mp4`
            }
        },
        // watch: {
        //     accountAddress(v) {
        //         if (this.isValidNetwork) {
        //             this.checkPack(v)
        //         }
        //     },
        //     isValidNetwork(v) {
        //         if (v && this.accountAddress) {
        //             this.checkPack(this.accountAddress)
        //         }
        //     }
        // },
        methods: {
            // redirectToIntro() {
            //     window.location.href = '/'
            // },
            onVideoLoaded() {
                this.playing = true
            },
            openLink(link) {
                // window.location.href = link
                window.open(link, '_blank')
            }
            // checkPack(address) {
            //     packContractService.balanceOf(address)
            //         .then(balance => {
            //             if (!+balance) {
            //                 // this.redirectToIntro()
            //             } else {
            //                 this.checkingPack = false
            //             }
            //         })
            //         .catch(err => {
            //             console.error(err)
            //         })
            // },
            // onWindowFocus() {
            //     this.$refs.video.currentTime = 0
            //     this.$refs.video.play()
            //     this.playing = true
            // },
            // onWindowBlur() {
            //     this.$refs.video.pause()
            //     this.playing = false
            // }
        },
        beforeCreate() {
            if (window.innerWidth < 768) {
                this.$router.replace('/gallery')
            }
        },
        mounted() {
            // if (this.accountAddress) {
            //     this.checkingPack = false
            // }

            // if (this.authCheck && !this.accountAddress) {
                // this.redirectToIntro()
            // }
        }
        // mounted() {
        //     window.addEventListener('focus', this.onWindowFocus)
        //     window.addEventListener('blur', this.onWindowBlur)
        // },
        // beforeUnmount() {
        //     window.removeEventListener('focus', this.onWindowFocus)
        //     window.removeEventListener('blur', this.onWindowBlur)
        // }
    }
</script>

<style>
    .page-Shop header.rn-header.header--sticky {
        position: fixed!important;
        background: var(--background-color-2);
    }

    .page-Shop main.pb--100 {
        padding-bottom: 0!important;
    }
</style>

<style scoped>
    .video-wrapper {
        position: relative;
        display: grid;
        place-items: center;
        width: 100vw;
        height: 100vh;
        margin: 0 auto;
        background: #000;
        overflow: hidden;
        opacity: 0;
        transition: opacity 2s;
    }

    .video-wrapper.playing {
        opacity: 1;
    }

    video,
    .anchors {
        position: absolute;
        z-index: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    video::-webkit-media-controls {
        display: none !important;
    }

    rect.chestAnchor {
        animation-direction: alternate;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    .video-wrapper.playing rect.chestAnchor {
        animation-name: chestAnchorAnimation;
    }

    a rect {
        fill: transparent;
        filter: blur(10px);
        opacity: 0;
        transition: opacity 200ms ease-out;
    }

    a rect:hover {
        fill: #FFFFFF;
        opacity: .4;
    }

    text tspan {
        font-family: 'Komikula', sans-serif;
    }

    text.links tspan:not(:first-child) {
        cursor: pointer;
    }

    text.links tspan:not(:first-child):hover {
        text-decoration: underline;
    }

    @keyframes chestAnchorAnimation {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(-20px);
        }
    }
</style>
