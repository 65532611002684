import {createRouter, createWebHistory} from 'vue-router'
// import HomePageOne from '../views/home/HomePageOne'
// import HomePageTwo from '../views/home/HomePageTwo'
// import HomePageThree from '../views/home/HomePageThree'
// import HomePageFour from '../views/home/HomePageFour'
// import HomePageFive from '../views/home/HomePageFive'
// import HomePageSix from '../views/home/HomePageSix'
// import HomePageSeven from '../views/home/HomePageSeven'
// import HomePageEight from '../views/home/HomePageEight'
// import HomePageNine from '../views/home/HomePageNine'
// import CreateProduct from '../views/CreateProduct'
import TokenSinglePage from '../views/TokenSinglePage'
// import Connect from '../views/Connect'
// import Collection from '../views/Collection'
// import Creators from '../views/Creators'
import MyAccount from '../views/MyAccount'
// import EditProfilePage from '../views/EditProfilePage'
// import About from '../views/About'
// import Contact from '../views/Contact'
// import BlogDetails from '../views/blog/BlogDetails'
// import BlogCategoryPosts from '../views/blog/BlogCategoryPosts'
// import BlogTagPosts from '../views/blog/BlogTagPosts'
// import BlogSingleColumn from '../views/blog/BlogSingleColumn'
// import BlogTwoColumn from '../views/blog/BlogTwoColumn'
// import BlogThreeColumn from '../views/blog/BlogThreeColumn'
// import BlogFourColumn from '../views/blog/BlogFourColumn'
import Gallery from '../views/Gallery'
// import ExploreOne from '../views/ExploreOne'
// import ExploreTwo from '../views/ExploreTwo'
// import ExploreThree from '../views/ExploreThree'
// import ExploreFour from '../views/ExploreFour'
// import ExploreFive from '../views/ExploreFive'
// import ExploreSix from '../views/ExploreSix'
// import ExploreSeven from '../views/ExploreSeven'
// import ExploreEight from '../views/ExploreEight'
// import ExploreNine from '../views/ExploreNine'
// import ExploreTen from '../views/ExploreTen'
// import ExploreEleven from '../views/ExploreEleven'
// import ExploreTwelve from '../views/ExploreTwelve'
// import ExploreThirteen from '../views/ExploreThirteen'
// import UploadVariants from '../views/UploadVariants'
// import Activity from '../views/Activity'
// import UpcomingProjects from '../views/UpcomingProjects'
// import Login from '../views/Login'
// import Signup from '../views/Signup'
// import ForgetPassword from '../views/ForgetPassword'
// import PrivacyPolicy from '../views/PrivacyPolicy'
// import Products from '../views/Products'
import Leaderboard from '../views/Leaderboard'
import NotFound from '../views/NotFound'
// import Support from '../views/Support'
// import TermsCondition from '../views/TermsCondition'
// import ComingSoon from '../views/ComingSoon'
// import Maintenance from '../views/Maintenance'
import Welcome from '../views/Welcome'
import Shop from '../views/Shop'

const routes = [
    {
        path: '/',
        name: 'Welcome',
        component: Welcome,
        meta: {
            title: 'Welcome | Capsule House',
        },
    },
    {
        path: '/shop',
        name: 'Shop',
        component: Shop,
        meta: {
            title: 'Shop | Capsule House',
        },
    },
    {
        path: '/card/:id',
        name: 'TokenSinglePage',
        component: TokenSinglePage,
        meta: {
            title: 'Card #:id | Capsule House',
        },
    },
    {
        path: '/myaccount',
        name: 'MyAccount',
        component: MyAccount,
        meta: {
            title: 'My Account | Capsule House',
        },
    },
    {
        path: '/gallery',
        name: 'CHCC: Gallery',
        component: Gallery,
        meta: {
            title: 'CHCC: Gallery | Capsule House',
        },
    },
    {
        path: '/leaderboard',
        name: 'Leaderboard',
        component: Leaderboard,
        meta: {
            title: 'Leaderboard | Capsule House',
        },
    },
    {
        path: '/:pathMatch(.*)',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: '404 Not Found',
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    let title = to.meta.title

    for (const param in to.params) {
        title = title.replace(`:${param}`, to.params[param])
    }

    document.title = title;
    next();
    window.scrollTo(0, 0)
});

export default router
