<template>
  <div class="outer"><div class="inner" :style="`width: ${percent}%; background-color: ${color};`"></div></div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    max: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    percent() {
      return (this.value / this.max) * 100
    }
  }
}
</script>

<style scoped>
  .outer {
    width: 100%;
    background-color: var(--background-color-1);
  }

  .inner {
    transition: width 500ms ease-out;
  }

  .outer,
  .inner {
    height: 10px;
    border-radius: 100px;
  }
</style>
