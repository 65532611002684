<template>
  <a :class="`${theme ? 'badge-' + theme : ''}`">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    theme: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
  a {
    background: transparent;
    display: inline-block;
    color: var(--color-body);
    font-size: 9px !important;
    text-decoration: none;
    border: 2px solid var(--color-border);
    transition: 0.4s;
    padding: 0 4px;
    border-radius: 100px;
    cursor: pointer;
  }

  a:hover {
    background: var(--color-primary);
    color: #ffffff !important;
    border-color: var(--color-primary) !important;
  }

  a.badge-common {
    background: #FFFFFF;
    border-color: #FFFFFF;
    color: black;
  }

  a.badge-common:hover {
    border-color: #FFFFFF !important;
    color: black !important;
  }

  a.badge-rare {
    background: #FFD700;
    border-color: #FFD700;
    color: black;
  }

  a.badge-rare:hover {
    border-color: #FFD700 !important;
    color: black !important;
  }

  a.badge-legendary {
    background: #00BF94;
    border-color: #00BF94;
    color: black;
  }

  a.badge-legendary:hover {
    border-color: #00BF94 !important;
    color: black !important;
  }

  a.badge-mythic {
    background: #FF4ABD;
    border-color: #FF4ABD;
    color: black;
  }

  a.badge-mythic:hover {
    border-color: #FF4ABD !important;
    color: black !important;
  }

  @media (min-width: 320px) and (max-width: 419px) {
    a {
      font-size: 12px!important;
    }
  }

  @media (min-width: 420px) and (max-width: 575px) {
    a {
      font-size: 14px!important;
    }
  }
</style>
