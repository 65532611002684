<template>
    <div :class="`page-${$route.name}`">
        <Header/>
        <mobile-menu/>
        <main class="pb--100">
            <slot></slot>
        </main>
        <back-to-top @click="scrollTop"/>
    </div>
</template>

<script>
    import Header from './header/Header'
    import MobileMenu from './header/MobileMenu'
    import BackToTop from '@/components/layouts/footer/BackToTop'

    export default {
        name: 'Layout',
        components: {BackToTop, MobileMenu, Header},
        methods: {
            scrollTop() {
                window.scrollTo(0, 0);
            }
        }
    }
</script>
