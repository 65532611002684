<template>
    <layout>
        <div class="rn-upcoming-area rn-section-gap pt--50">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="table-title-area d-flex">
                            <i class="feather-bar-chart"/>
                            <h3>LEADERBOARD</h3>
                        </div>
                        <div v-if="loading" class="text-center">
                            <img :src="require('@/assets/images/loader.svg')" alt="Loading..." width="150">
                        </div>
                        <div v-else class="box-table table-responsive">
                            <table class="table upcoming-projects">
                                <thead>
                                    <tr>
                                        <th>
                                            <span class="text-capitalize">
                                                Rank
                                            </span>
                                        </th>
                                        <th>
                                            <span class="text-capitalize">
                                                Address
                                            </span>
                                        </th>
                                        <th>
                                            <span class="text-capitalize">
                                                Owned
                                            </span>
                                        </th>
                                        <th>
                                            <span class="text-capitalize">
                                                Score
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="ranking">
                                    <tr :class="{'color-light': rowIndex%2 === 0}" v-for="(row, rowIndex) in filteredRows.slice(pageStart, pageStart + countOfPage)" :key="`upcoming-project-${rowIndex}`">
                                        <td :class="{'font--bold bg-primary': row.address == accountAddress}">
                                            <span>{{ row.rank || '-' }}{{ row.rank > 0 ? '.' : '' }}</span>
                                        </td>
                                        <td :class="{'font--bold bg-primary': row.address == accountAddress}">
                                            <a :href="holderLink(row.address)" target="_blank">
                                                <img v-if="row.ens" class="ens-icon" :src="require('@/assets/images/icons/ENS.svg')" alt="ENS icon">
                                                <span>{{ row.ens || row.address }}</span>
                                            </a>
                                        </td>
                                        <td :class="{'font--bold bg-primary': row.address == accountAddress}">
                                            <span>{{ row.owned || '-' }}</span>
                                        </td>
                                        <td :class="{'font--bold bg-primary': row.address == accountAddress}">
                                            <span>{{ row.score || '-' }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    import { mapState } from 'vuex'
    import http from '@/services/httpService'
    import Layout from '@/components/layouts/Layout'

    export default {
        name: 'Leaderboard',
        components: {Layout},
        data() {
            return {
                loading: true,
                rankingList: [],
                currPage: 1,
                countOfPage: 26
            }
        },
        computed: {
            ...mapState([
                'accountAddress'
            ]),
            filteredRows() {
                return this.rankingList;
            },
            pageStart() {
                return (this.currPage - 1) * this.countOfPage;
            },
            totalPage() {
                return Math.ceil(this.filteredRows.length / this.countOfPage);
            }
        },
        watch: {
            accountAddress() {
                this.fetchLeaderboard()
            }
        },
        methods: {
            setPage(idx) {
                if(idx <= 0 || idx > this.totalPage) {
                    return;
                }
                this.currPage = idx;
                window.scrollTo(0, 0);
            },
            holderLink(address) {
                // const contractAddress = process.env.VUE_APP_CARD_CONTRACT_ADDRESS
                // const networkName = process.env.VUE_APP_NETWORK_NAME

                // return `https://${networkName == 'mainnet' ? '' : networkName + '.'}etherscan.io/token/${contractAddress}?a=${address}`
                return `https://opensea.io/${address}`
            },
            fetchLeaderboard() {
                let url = '/contract/leaderboard'

                if (this.accountAddress) {
                    url += '?account=' + this.accountAddress
                }

                http.get(url)
                    .then(response => {
                        const { ranking, you } = response.data

                        this.rankingList = ranking

                        if (you && (you.rank == 0 || you.rank > 25)) {
                            this.rankingList.push(you)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        mounted() {
            this.fetchLeaderboard()
        }
    }
</script>

<style scoped>
    .box-table {
        border: 0;
    }

    .font--bold {
        font-weight: 700!important;
    }

    .ranking a {
        text-decoration: none;
    }

    .ranking a span {
        font-family: 'Brown', sans-serif;
        transition: color 300ms;
    }

    .ranking a:hover span {
        color: white;
    }

    .ens-icon {
        width: 15px;
        margin-right: 5px;
    }
</style>
