<template>
    <header class="rn-header haeder-default header--sticky">
        <div class="container">
            <div class="header-inner">
                <div class="header-left">
                    <logo/>
                    <h3 class="d-block d-lg-none ml--20 mb--0">
                        {{ pageTitle }}
                    </h3>
                    <div class="mainmenu-wrapper">
                        <nav class="mainmenu-nav d-none d-lg-block">
                            <Nav/>
                        </nav>
                    </div>
                </div>
                <div class="header-right">
                    <div v-if="accountAddress" class="setting-option d-none d-lg-block">
                        <router-link to="/myaccount" class="header-right-nav-link">
                            MY ACCOUNT
                        </router-link>
                    </div>
                    <!-- <a href="https://discord.com" target="_blank" class="d-none d-lg-flex social-link ml--5 mr--5">
                        <img :src="require('@/assets/images/icons/discord.svg')" alt="Discord">
                    </a>
                    <a href="https://twitter.com" target="_blank" class="d-none d-lg-flex social-link ml--5 mr--5">
                        <img :src="require('@/assets/images/icons/twitter.svg')" alt="Twitter">
                    </a> -->
                    <SocialLinks class="d-none d-lg-flex" />
                    <div class="setting-option header-btn rbt-site-header flex-shrink-0 d-none d-lg-block" id="rbt-site-header">
                        <div class="icon-box">
                            <a @click="connectWallet" href="javascript:void(0);" id="connectbtn" :class="`btn btn-primary-alta btn-small${accountAddress ? ' btn-connected' : ''}`">
                                {{ formattedAccountAddress }}
                            </a>
                        </div>
                    </div>
                    <div v-if="accountAddress" class="setting-option header-btn rbt-site-header flex-shrink-0 d-none d-lg-block" id="rbt-site-header">
                        <div class="icon-box">
                            <a @click="disconnectWallet" href="javascript:void(0);" id="disconnectbtn" class="btn btn-small">
                                <i class="feather-log-out"></i>
                            </a>
                        </div>
                    </div>
                    <div class="setting-option mobile-menu-bar d-block d-lg-none">
                        <div class="hamberger">
                            <button class="hamberger-button"
                                    @click="AppFunctions.addClass('.popup-mobile-menu', 'active')">
                                <i class="feather-menu"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import {mapState} from 'vuex'
    import Nav from './Nav';
    import AppFunctions from '../../../helpers/AppFunctions';
    import Logo from "@/components/logo/Logo";
    import SocialLinks from '@/components/misc/SocialLinks'

    export default {
        name: 'Header',
        components: {Logo, Nav, SocialLinks},
        data() {
            return {
                AppFunctions,
                isMobileSearchActive: false
            }
        },
        computed: {
            ...mapState([
                'accountAddress'
            ]),
            formattedAccountAddress() {
                if (this.accountAddress) {
                    return `${this.accountAddress.slice(0, 6)}...${this.accountAddress.slice(-4)}`
                }

                return 'CONNECT WALLET'
            },
            pageTitle() {
                return document.title.replace(' | Capsule House', '')
            }
        },
        methods: {
            connectWallet() {
                if (!this.accountAddress) {
                    this.$wallet.connect()
                    document.body.style.overflow = ''
                }
            },
            disconnectWallet() {
                this.$wallet.disconnect()
            }
        },
        mounted() {
            const header = document.querySelector('.header--sticky');
            const setStickyHeader = () => {
                const scrollPos = window.scrollY;
                if (scrollPos > 50) {
                    header.classList.add('sticky');
                } else {
                    header.classList.remove('sticky');
                }
            }
            window.addEventListener('scroll', setStickyHeader);
        }
    }
</script>

<style scoped>
    #connectbtn {
        color: white;
        background-color: #6c7386;
        font-family: 'Brown', sans-serif;
    }

    .btn-connected {
        background-color: var(--background-color-1)!important;
        cursor: default;
    }

    #connectbtn:hover {
        background-color: #FF4ABD;
    }

    #connectbtn,
    #disconnectbtn {
        border-radius: 100px;
        padding: 15px 30px;
        font-size: 12px;
        font-weight: 700;
    }

    #disconnectbtn {
        color: white;
        font-size: 16px;
        padding: 10px 15px
    }

    #disconnectbtn:hover {
        color: var(--color-primary);
    }

    .rn-header {
        border-bottom: 1px solid var(--color-border);
        transition: none;
    }

    .rn-header.sticky {
        background: var(--background-color-2);
    }

    .rn-header.header--sticky {
        backdrop-filter: initial!important;
    }

    .header-right-nav-link {
        font-family: 'Brown', sans-serif;
        font-size: 13px;
        font-weight: 700;
        color: white!important;
        padding: 21px 12px;
    }

    .header-left {
        flex-basis: 70%!important;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .header-right-nav-link {
            font-size: 11px;
        }

        .header-right {
            flex-basis: 55%!important;
        }

        #connectbtn {
            padding: 15px 15px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1399px) {
        .header-left {
            flex-basis: 60%!important;
        }
    }
</style>
