<template>
  <span>
    <slot />
    <i class="feather-x close" @click="close"></i>
  </span>
</template>

<script>
export default {
  name: 'FilterBadge',
  props: {
    group: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close', this.group, this.value)
    }
  }
}
</script>

<style scoped>
  span {
    height: 50px;
    border-radius: 25px;
    background-color: #3a415f;
    padding: 18px 20px;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1em;
    font-family: "Brown", sans-serif;
    display: flex;
    color: #fff;
    /* margin-right: 10px;
    margin-bottom: 10px; */
    align-items: center;
    user-select: none;
  }

  span .close {
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    transform: scale(1);
    transition: 0.25s;
  }

  span .close:hover {
    transform: scale(1.5);
  }
</style>
