<template>
    <layout>
        <div v-if="!accountAddress" class="rn-author-area pt--100 mb--30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="rn-not-found-wrapper">
                            <h3 class="title">
                                Oops!
                            </h3>
                            <p>
                                You must be connected to your wallet to see this page.
                            </p>
                            <div class="setting-option header-btn rbt-site-header flex-shrink-0" id="rbt-site-header">
                                <div class="icon-box">
                                    <a @click="connectWallet" href="javascript:void(0);" id="connectbtn" class="btn btn-primary-alta btn-small">
                                        CONNECT WALLET
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-else>
            <div class="rn-author-area mb--30">
                <div class="container">
                    <div class="row padding-tb-50 align-items-center d-flex pt--50">
                        <div class="col-lg-12">
                            <div class="author-wrapper">
                                <div class="author-inner">
                                    <img v-if="loadingENSAvatar || loadingENSName" :src="require('@/assets/images/loader.svg')" alt="Loading..." width="150">
                                    <template v-else>
                                        <div class="user-thumbnail">
                                            <img :src="accountDetails.avatar || require('@/assets/images/logo/logo-250x250.png')" alt="Avatar">
                                        </div>
                                        <div class="rn-author-info-content">
                                            <h4 class="title">
                                                <a :href="holderLink" target="_blank">
                                                    {{ accountDetails.name || formattedAccountAddress }}
                                                </a>
                                            </h4>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <div class="collection-progress">
                                <strong>My Collection</strong>
                                <ProgressBar :value="accountDetails.tokenCount.all" :max="410" color="#ffffff" />
                                <small class="d-block text-muted">{{ accountDetails.tokenCount.all }}/410</small>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="collection-progress">
                                        <strong>Common</strong>
                                        <ProgressBar :value="accountDetails.tokenCount.common" :max="410" color="#FFFFFF" />
                                        <small class="d-block text-muted">{{ accountDetails.tokenCount.common }}/410</small>
                                    </div>
                                    <div class="collection-progress">
                                        <strong>Rare</strong>
                                        <ProgressBar :value="accountDetails.tokenCount.rare" :max="410" color="#FFD700" />
                                        <small class="d-block text-muted">{{ accountDetails.tokenCount.rare }}/410</small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="collection-progress">
                                        <strong>Legendary</strong>
                                        <ProgressBar :value="accountDetails.tokenCount.legendary" :max="410" color="#00BF94" />
                                        <small class="d-block text-muted">{{ accountDetails.tokenCount.legendary }}/410</small>
                                    </div>
                                    <div class="collection-progress">
                                        <strong>Mythic</strong>
                                        <ProgressBar :value="accountDetails.tokenCount.mythic" :max="410" color="#FF4ABD" />
                                        <small class="d-block text-muted">{{ accountDetails.tokenCount.mythic }}/410</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="rn-authore-profile-area">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div v-if="hasTokens" class="tab-wrapper-one">
                                <nav class="tab-button-one">
                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button @click="handleNavLinkFilter('')"
                                                :class="`nav-link${filters.rarity ? '' : ' active'}`"
                                                id="nav-all-cards-tab"
                                                type="button" role="tab"
                                                aria-controls="nav-all-cards"
                                                aria-selected="false">
                                            All Cards
                                        </button>
                                        <button @click="handleNavLinkFilter('Common')"
                                                :class="`nav-link${filters.rarity == 'Common' ? ' active' : ''}`"
                                                id="nav-common-tab"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-common"
                                                aria-selected="true">
                                            Common
                                        </button>
                                        <button @click="handleNavLinkFilter('Rare')"
                                                :class="`nav-link${filters.rarity == 'Rare' ? ' active' : ''}`"
                                                id="nav-rare-tab"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-rare"
                                                aria-selected="false">
                                            Rare
                                        </button>
                                        <button @click="handleNavLinkFilter('Legendary')"
                                                :class="`nav-link${filters.rarity == 'Legendary' ? ' active' : ''}`"
                                                id="nav-legendary-tab"
                                                type="button" role="tab"
                                                aria-controls="nav-legendary"
                                                aria-selected="false">
                                            Legendary
                                        </button>
                                        <button @click="handleNavLinkFilter('Mythic')"
                                                :class="`nav-link${filters.rarity == 'Mythic' ? ' active' : ''}`"
                                                id="nav-mythic-tab"
                                                type="button" role="tab"
                                                aria-controls="nav-mythic"
                                                aria-selected="false">
                                            Mythic
                                        </button>
                                    </div>
                                </nav>
                            </div>

                            <div class="row g-5">
                                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"
                                    v-for="(item, index) in tokens"
                                    :key="`newest-item-${index}`">
                                    <token-card
                                        :token-data="item"
                                        :badges-clickable="false"
                                        token-style-class="no-overlay"
                                    />
                                </div>
                            </div>
                            <div v-if="!loadingMyTokens && !tokens.length" class="mt--50 text-center">
                                <svg style="width: 50px; height: 50px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-frown"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                <div class="mt--10">
                                    {{ filters.rarity == '' ? 'You dont have any tokens.' : 'No tokens found for this filter.' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </layout>
</template>

<script>
    import { mapState } from 'vuex'
    import http from '@/services/httpService'
    import Layout from '@/components/layouts/Layout'
    import TokenCard from '@/components/token/TokenCard'
    import ProgressBar from '@/components/misc/ProgressBar'

    export default {
        name: 'MyAccount',
        components: {TokenCard, ProgressBar, Layout},
        data() {
            return {
                loadingMyTokens: true,
                loadingENSAvatar: true,
                loadingENSName: true,
                tokens: [],
                hasTokens: false,
                currentPage: 1,
                perPage: 8,
                totalPages: 0,
                totalItems: 0,
                filters: {
                    rarity: ''
                },
                accountDetails: {
                    avatar: null,
                    name: null,
                    tokenCount: {
                        all: 0,
                        common: 0,
                        rare: 0,
                        legendary: 0,
                        mythic: 0
                    }
                }
            }
        },
        computed: {
            ...mapState([
                'accountAddress'
            ]),
            formattedAccountAddress() {
                return `${this.accountAddress.slice(0, 6)}...${this.accountAddress.slice(-4)}`
            },
            holderLink() {
                // const contractAddress = process.env.VUE_APP_CARD_CONTRACT_ADDRESS
                // const networkName = process.env.VUE_APP_NETWORK_NAME

                // return `https://${networkName == 'mainnet' ? '' : networkName + '.'}etherscan.io/token/${contractAddress}?a=${this.accountAddress}`
                return `https://opensea.io/${this.accountAddress}`
            }
        },
        watch: {
            accountAddress() {
                this.tokens = []
                this.currentPage = 1
                this.filters.rarity = ''
                this.hasTokens = false
                this.fetchMyTokens()
                this.fetchTotalByRarity()
                this.fetchENSAvatar()
                this.fetchENSName()
            }
        },
        methods: {
            connectWallet() {
                if (!this.accountAddress) {
                    this.$wallet.connect()
                    document.body.style.overflow = ''
                }
            },
            fetchENSAvatar() {
                this.loadingENSAvatar = true

                http.get(`/ens/${this.accountAddress}/avatar`)
                    .then(response => {
                        this.accountDetails.avatar = response.data.avatar
                    })
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        this.loadingENSAvatar = false
                    })
            },
            fetchENSName() {
                this.loadingENSName = true

                http.get(`/ens/${this.accountAddress}/name`)
                    .then(response => {
                        this.accountDetails.name = response.data.name
                    })
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        this.loadingENSName = false
                    })
            },
            fetchTotalByRarity() {
                http.get(`/tokens/${this.accountAddress}/rarities`)
                    .then(response => {
                        if (response.data) {
                            this.accountDetails.tokenCount = response.data
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    })
            },
            fetchMyTokens() {
                this.loadingMyTokens = true
                const filters = {
                    rarity: this.filters.rarity,
                    account: this.accountAddress,
                    owned: 1
                }

                http.get(`/tokens?page=${this.currentPage}&perPage=${this.perPage}&${new URLSearchParams(filters)}`)
                    .then(response => {
                        this.tokens.push(...response.data.items)
                        this.totalPages = response.data.totalPages
                        this.totalItems = response.data.totalItems

                        if (this.tokens.length > 0) {
                            this.hasTokens = true
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        this.loadingMyTokens = false
                    })
            },
            handleNavLinkFilter(rarity) {
                if (this.filters.rarity == rarity) {
                    return
                }

                this.filters.rarity = rarity
                this.tokens = []
                this.currentPage = 1
                this.fetchMyTokens()
            },
            onWindowScroll(e) {
                if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 300) && !this.loadingMyTokens && this.currentPage < this.totalPages) {
                    this.currentPage++
                    this.fetchMyTokens()
                }
            }
        },
        mounted() {
            if (this.accountAddress) {
                this.fetchMyTokens()
                this.fetchTotalByRarity()
                this.fetchENSAvatar()
                this.fetchENSName()
            }

            window.addEventListener('scroll', this.onWindowScroll)
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.onWindowScroll)
        }
    }
</script>

<style scoped>
    h4.title {
        font-family: 'Brown', sans-serif;
    }

    .collection-progress {
        text-align: left;
        font-family: 'Brown', sans-serif;
    }

    .collection-progress small {
        margin-top: 4px;
        margin-bottom: 8px;
    }

    .collection-progress strong {
        display: block;
        margin-bottom: 4px;
    }

    .collection-progress progress {
        width: 100%;
    }

    .collection-progress progress::-webkit-progress-bar {
        background-color: yellow;
    }

    .collection-progress progress::-webkit-progress-value {
        background-color: blue;
    }

    .collection-progress progress::-moz-progress-bar {
        background-color: yellow;
    }

    .tab-button-one .nav-tabs {
        border: 0;
    }

    .tab-button-one .nav-tabs,
    .tab-button-one .nav-tabs button {
        border-radius: 35px!important;
    }

    .tab-button-one .nav-tabs .nav-link.active {
        pointer-events: none;
    }

    #nav-all-cards-tab:focus {
        border-color: #ffffff!important;
    }

    #nav-common-tab {
        color: #FFFFFF;
    }

    #nav-common-tab:focus {
        border-color: #FFFFFF!important;
    }

    #nav-common-tab.active {
        color: black;
        background-color: #FFFFFF;
    }

    #nav-rare-tab {
        color: #FFD700;
    }

    #nav-rare-tab:focus {
        border-color: #FFD700!important;
    }

    #nav-rare-tab.active {
        color: black;
        background-color: #FFD700;
    }

    #nav-legendary-tab {
        color: #00BF94;
    }

    #nav-legendary-tab:focus {
        border-color: #00BF94!important;
    }

    #nav-legendary-tab.active {
        color: black;
        background-color: #00BF94;
    }

    #nav-mythic-tab {
        color: #FF4ABD;
    }

    #nav-mythic-tab:focus {
        border-color: #FF4ABD!important;
    }

    #nav-mythic-tab.active {
        color: black;
        background-color: #FF4ABD;
    }

    .author-wrapper .author-inner .user-thumbnail img {
        border-color: var(--background-color-1);
    }

    #connectbtn {
        border-radius: 100px;
        padding: 15px 30px;
        font-size: 12px;
        font-weight: 700;
        color: white;
        background-color: #6c7386;
        font-family: 'Brown', sans-serif;
    }

    #connectbtn:hover {
        background-color: var(--color-primary);
    }
</style>
