<template>
    <div :class="`product-style-one ${tokenData.attributes.rarity.toLowerCase()} ${tokenStyleClass}`">
        <div class="card-thumbnail">
            <router-link :to="`/card/${tokenData.tokenId}`">
                <img :src="tokenImage(tokenData.tokenId)" :alt="tokenData.title" @load="$emit('handleImageLoad')">
            </router-link>
        </div>
        <div class="d-flex justify-content-between mt--30">
            <span>#{{ tokenData.tokenId }}</span>
            <div :class="`badges${badgesClickable ? ' badges-clickable' : ''}`">
                <Badge @click="handleBadgeClick('category', tokenData.attributes.category)" class="mr--5">
                    {{ tokenData.attributes.category }}
                </Badge>
                <Badge @click="handleBadgeClick('variant', tokenData.attributes.variant)">
                    {{ tokenData.attributes.variant || 'N/A' }}
                </Badge>
            </div>
        </div>
        <div class="product-share-wrapper mt--10">
            <div :class="`profile-share${tokenData.owners.length == 0 ? ' no-owners' : ''}`">
                {{ tokenData.owners.length }} owner{{ tokenData.owners.length == 1 ? '' : 's' }}
            </div>
        </div>
        <router-link :to="`/card/${tokenData.tokenId}`">
            <span class="product-name">
                {{ optimizedTitle }}
            </span>
        </router-link>
        <span :class="`latest-bid ${badgesClickable ? 'pointer' : ''}`" @click="handleBadgeClick('artist', tokenData.attributes.artist)">
            {{ tokenData.attributes.artist }}
        </span>
        <Badge class="badge-rarity float-end" :theme="rarityTheme">
            {{ tokenData.attributes.rarity }}
        </Badge>
    </div>
</template>

<script>
    import Badge from '@/components/misc/Badge';

    export default {
        name: 'TokenCard',
        components: {Badge},
        props: {
            tokenData: {},
            badgesClickable: {
                type: Boolean,
                default: true
            },
            tokenStyleClass: {
                type: String
            },
            showPlaceBid: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            optimizedTitle() {
                return this.tokenData.attributes.title.split(' - ')[0]
            },
            rarityTheme() {
                return this.tokenData.attributes.rarity.toLowerCase()
            }
        },
        watch: {
            '$route': function (to, from) {
                this.closeModal()
            }
        },
        methods: {
            closeModal() {
                const modals = document.querySelectorAll('.modal-backdrop');

                if (modals.length) {
                    modals.forEach(item => {
                        item.remove();
                    })
                }
            },
            handleBadgeClick(group, value) {
                if (value) {
                    this.$emit('badge-click', group, value)
                }
            },
            tokenImage(tokenId) {
                return `${process.env.VUE_APP_API_URL}/images/cards/${tokenId}.jpg`
            }
        }
    }
</script>

<style scoped>
    .product-style-one:hover .card-thumbnail a img {
        transform: scale(1.05);
    }

    .product-style-one .badges:not(.badges-clickable) a,
    .product-style-one .badge-rarity {
        pointer-events: none!important;
    }

    .product-style-one.common::before {
        background-image: linear-gradient(100deg, #252525, #FFFFFF);
    }

    .product-style-one.common a .product-name:hover {
        color: #FFFFFF!important;
    }

    .product-style-one.common .badges a:hover {
        color: black!important;
        background: #FFFFFF!important;
        border-color: #FFFFFF!important;
    }

    .product-style-one.rare::before {
        background-image: linear-gradient(100deg, #292408, #FFD700);
    }

    .product-style-one.rare a .product-name:hover {
        color: #FFD700!important;
    }

    .product-style-one.rare .badges a:hover {
        color: black!important;
        background: #FFD700!important;
        border-color: #FFD700!important;
    }

    .product-style-one.legendary::before {
        background-image: linear-gradient(100deg, #082c23, #00BF94);
    }

    .product-style-one.legendary a .product-name:hover {
        color: #00BF94!important;
    }

    .product-style-one.legendary .badges a:hover {
        color: black!important;
        background: #00BF94!important;
        border-color: #00BF94!important;
    }

    .product-style-one.mythic::before {
        background-image: linear-gradient(100deg, #38102a, #FF4ABD);
    }

    .product-style-one.mythic a .product-name:hover {
        color: #FF4ABD!important;
    }

    .product-style-one.mythic .badges a:hover {
        color: black!important;
        background: #FF4ABD!important;
        border-color: #FF4ABD!important;
    }

    .product-style-one::after {
        background: var(--background-color-1);
    }

    .product-style-one .card-thumbnail {
        overflow: initial;
    }

    .no-owners {
        color: gray;
    }

    @media (min-width: 320px) and (max-width: 419px) {
        .product-share-wrapper .profile-share {
            font-size: 14px!important;
        }

        .product-name {
            font-size: 20px!important;
        }

        .latest-bid {
            font-size: 18px!important;
        }
    }

    @media (min-width: 420px) and (max-width: 575px) {
        .product-share-wrapper .profile-share {
            font-size: 16px!important;
        }

        .product-name {
            font-size: 24px!important;
        }

        .latest-bid {
            font-size: 20px!important;
        }
    }
</style>
