<template>
    <div :class="{'video-wrapper': true, playing}">
        <svg class="anchors" viewBox="0 0 1000 563" xmlns="http://www.w3.org/2000/svg">
            <foreignObject x="0" y="0" width="100%" height="100%">
                <video :class="{'d-none': hovering}" ref="video1" xmlns="http://www.w3.org/1999/xhtml" muted playsinline loop autoplay @loadeddata="onVideoLoaded">
                    <source :src="videoUrl" type="video/mp4" />
                </video>
                <video :class="{'d-none': !hovering}" ref="video2" xmlns="http://www.w3.org/1999/xhtml" muted playsinline loop autoplay>
                    <source :src="videoHoverUrl" type="video/mp4" />
                </video>
            </foreignObject>
            <a href="javascript: void(0);" @click="handleClick" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
                <rect x="620" y="190" width="118" height="300" fill="transparent" />
                <rect x="738" y="190" width="195" height="130" fill="transparent" />
            </a>
            <image v-if="hovering" x="672" y="250" width="100" class="portal-enter-img fade-in" :href="require('@/assets/images/intro/enter.png')" />
        </svg>

        <div v-if="hovering" class="portal-message fade-in" :style="`background-image: url(${require('@/assets/images/intro/bubble2.png')})`">
            <TextWritter text="Yes, yes, I have exactly what a collector like you has been searching for. Do come inside." />
        </div>

        <div v-if="playing" class="welcome-message fade-in" :style="`background-image: url(${require('@/assets/images/intro/bubble1.png')})`">
            <TextWritter text="Weary traveler! I've been expecting you." />
        </div>
    </div>
</template>

<script>
    import TextWritter from '@/components/misc/TextWritter'

    export default {
        name: 'Welcome',
        components: {TextWritter},
        data() {
            return {
                playing: false,
                hovering: false
            }
        },
        computed: {
            videoUrl() {
                return `${process.env.VUE_APP_API_URL}/videos/burn-chest-intro.mp4`
            },
            videoHoverUrl() {
                return `${process.env.VUE_APP_API_URL}/videos/burn-chest-intro-hover.mp4`
            }
        },
        methods: {
            onVideoLoaded() {
                this.playing = true
            },
            connectWallet() {
                this.$wallet.connect()
            },
            handleClick() {
                this.$router.push('/shop')
            },
            onMouseEnter() {
                this.$refs.video2.currentTime = this.$refs.video1.currentTime
                this.hovering = true
            },
            onMouseLeave() {
                this.$refs.video1.currentTime = this.$refs.video2.currentTime
                this.hovering = false
            }
        },
        beforeCreate() {
            if (window.innerWidth < 768) {
                this.$router.replace('/gallery')
            }
        }
    }
</script>

<style scoped>
    .video-wrapper {
        position: relative;
        display: grid;
        place-items: center;
        width: 100vw;
        height: 100vh;
        margin: 0 auto;
        background: #000;
        overflow: hidden;
        opacity: 0;
        transition: opacity 2s;
    }

    .video-wrapper.playing {
        opacity: 1;
    }

    video,
    .anchors {
        position: absolute;
        z-index: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    video::-webkit-media-controls {
        display: none !important;
    }

    .fade-in {
        animation-name: fadeInAnimation;
        animation-direction: normal;
        animation-duration: 2s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
    }

    .portal-enter-img {
        pointer-events: none;
    }

    .welcome-message {
        font-family: 'Komikula', sans-serif;
        font-size: 18px;
        color: white;
        width: 200px;
        height: 112px;
        margin-left: -200px;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        text-align: center;
        position: absolute;
        padding: 28px 24px 24px 24px;
    }

    .portal-message {
        font-family: 'Komikula', sans-serif;
        font-size: 16px;
        color: white;
        width: 250px;
        height: 153px;
        margin-top: -300px;
        margin-left: 300px;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        text-align: center;
        position: absolute;
        padding: 38px 24px 24px 24px;
    }

    @keyframes fadeInAnimation {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @media (min-width: 1280px) {

    }

    @media (min-width: 1360px) {

    }

    @media (min-width: 1920px) {

    }

    @media (min-width: 2560px) {

    }

    @media (min-width: 3840px) {
        .welcome-message,
        .portal-message {
            font-size: 32px;
        }

        .welcome-message {
            width: 400px;
            height: 224px;
            margin-left: -300px;
            padding-top: 60px;
        }

        .portal-message {
            width: 500px;
            height: 306px;
            margin-top: -600px;
            margin-left: 500px;
            padding: 75px 32px 32px 32px;
        }
    }
</style>
