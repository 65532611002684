<template>
  <div class="d-flex">
    <a href="http://discord.gg/capsulehouse" target="_blank" class="social-link">
      <img :src="require('@/assets/images/icons/discord.svg')" alt="Discord">
    </a>
    <a href="https://twitter.com/capsule_house" target="_blank" class="social-link">
      <img :src="require('@/assets/images/icons/twitter.svg')" alt="Twitter">
    </a>
  </div>
</template>

<style scoped>
  .social-link {
    display: flex!important;
    border-radius: 35px;
    border: 1px solid #3a415f;
    width: 40px;
    height: 40px;
    justify-content: center!important;
    align-items: center!important;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .social-link:first-child {
    margin-right: 5px;
  }

  .social-link img {
    width: 18px;
  }

  .social-link:hover {
    background-color: #3a415f;
  }

  @media (min-width: 992px) {
    .social-link {
      width: 50px;
      height: 50px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .social-link img {
      width: 20px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .social-link {
      width: 40px;
      height: 40px;
    }
  }
</style>
