import {createApp} from 'vue'
import Toast, {POSITION} from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import {store} from './store'
import App from './App.vue'
import router from './router'
import Wallet from './plugins/Wallet'
import './assets/css/feature.css'
import './assets/scss/style.scss'
import 'bootstrap'

createApp(App)
  .use(router)
  .use(Wallet)
  .use(store)
  .use(Toast, {
    position: POSITION.TOP_CENTER
  })
  .mount('#app')
