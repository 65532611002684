<template>
    <ul class="mainmenu">
        <li v-if="accountAddress" class="d-block d-lg-none">
            <router-link to="/myaccount" class="header-right-nav-link">
                My Account
            </router-link>
        </li>
        <li v-for="(item, index) in navItems" :class="[item.hasDropdown ? 'has-droupdown has-menu-child-item' : '', item.megamenu ? 'with-megamenu' : '']" :key="`nav-item-${index}`">
            <a v-if="item.external" :href="item.link" class="nav-link its_new" target="_blank">
                {{ item.title }}
                <i :class="`feather-${item.icon} d-block d-lg-none`"/>
            </a>
            <router-link v-else :to="item.link" class="nav-link its_new">
                {{ item.title }}
            </router-link>
            <ul v-if="item.hasDropdown" class="submenu">
                <li v-for="(subMenuItem, subMenuIndex) in item.dropdownItems"
                    :key="`sub-menu-item-${subMenuIndex}`">
                    <a v-if="subMenuItem.external" :href="subMenuItem.link" :class="subMenuItem.class" target="_blank">
                        {{ subMenuItem.title }}
                        <i v-if="subMenuItem.class !== 'live-expo'" :class="`feather-${subMenuItem.icon}`"/>
                    </a>
                    <router-link v-else :href="subMenuItem.link" :class="subMenuItem.class">
                        {{ subMenuItem.title }}
                        <i v-if="subMenuItem.class !== 'live-expo'" :class="`feather-${subMenuItem.icon}`"/>
                    </router-link>
                </li>
            </ul>
            <div v-if="item.megamenu" class="rn-megamenu">
                <div class="wrapper">
                    <div class="row row--0">
                        <div v-for="(megamenuItem, megamenuIndex) in item.megaMenuItems"
                             :key="`mega-menu-item-${megamenuIndex}`"
                             class="col-lg-3 single-mega-item">
                            <ul class="mega-menu-item">
                                <li v-for="(megamenuSubItem, megamenuSubIndex) in megamenuItem.items"
                                    :key="`mega-submenu-item-${megamenuSubIndex}`">
                                    <router-link :to="megamenuSubItem.link">
                                        {{ megamenuSubItem.title }} <i :class="`feather-${megamenuSubItem.icon}`"/>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="d-flex d-lg-none">
            <SocialLinks />
        </li>
        <li class="d-block d-lg-none">
            <a @click="connectWallet" href="javascript:void(0);" :class="`connect-wallet btn btn-small${accountAddress ? ' btn-connected' : ''}`">
                {{ formattedAccountAddress }}
            </a>

            <div v-if="accountAddress" class="text-center">
                <a @click="disconnectWallet" href="javascript: void(0);" class="disconnect-wallet d-inline-block">
                    <i class="feather-log-out"></i>
                    Disconnect
                </a>
            </div>
        </li>
    </ul>
</template>

<script>
import { mapState } from 'vuex'
import AppFunctions from '@/helpers/AppFunctions'
import SocialLinks from '@/components/misc/SocialLinks'

export default {
    name: 'Nav',
    components: {SocialLinks},
    data() {
        return {
            AppFunctions,
            navItems: [
                {
                    link: '',
                    title: 'Collections',
                    hasDropdown: true,
                    megamenu: false,
                    external: false,
                    dropdownItems: [
                        {link: 'https://medium.com/@capsulehouselore', title: 'Tale of the Kitsune', icon: 'external-link', external: true},
                        {link: 'https://opensea.io/collection/capsulehouse', title: 'Capsule House', icon: 'external-link', external: true},
                        {link: 'https://opensea.io/collection/zodiac-capsules', title: 'Zodiac Capsules', icon: 'external-link', external: true},
                        {link: 'https://opensea.io/collection/capsule-zodiac-cards', title: 'Capsule Zodiac Cards', icon: 'external-link', external: true},
                        {link: 'https://opensea.io/collection/capsule-community-curated', title: 'Community Curated', icon: 'external-link', external: true},
                        {link: 'https://opensea.io/collection/chcc-genesis-chest', title: 'CHCC: Genesis Chest', icon: 'external-link', external: true},
                        {link: 'https://opensea.io/collection/chcc-genesis-collection', title: 'CHCC: Genesis Collection', icon: 'external-link', external: true},
                    ]
                },
                {
                    link: '/gallery',
                    title: 'CHCC: Gallery',
                    hasDropdown: false,
                    megamenu: false,
                    external: false,
                },
                {
                    link: '/leaderboard',
                    title: 'Leaderboard',
                    hasDropdown: false,
                    megamenu: false,
                    external: false,
                },
                {
                    link: 'https://opensea.io/collection/chcc-genesis-chest/drop',
                    title: 'Mint',
                    hasDropdown: false,
                    megamenu: false,
                    external: true,
                    icon: 'external-link'
                },
                {
                    link: 'https://cards.capsulehouse.io/burn',
                    title: 'Burn',
                    hasDropdown: false,
                    megamenu: false,
                    external: true,
                    icon: 'external-link'
                }
            ]
        }
    },
    computed: {
        ...mapState([
            'accountAddress'
        ]),
        formattedAccountAddress() {
            if (this.accountAddress) {
                return `${this.accountAddress.slice(0, 6)}...${this.accountAddress.slice(-4)}`
            }

            return 'CONNECT WALLET'
        }
    },
    methods: {
        async connectWallet() {
            if (!this.accountAddress) {
                await this.$wallet.connect()
                AppFunctions.removeClass('.popup-mobile-menu', 'active')
            }
        },
        disconnectWallet() {
            this.$wallet.disconnect()
        }
    }
}
</script>

<style scoped>
    .mainmenu-nav .mainmenu li a {
        padding: 21px 12px;
        font-family: 'Brown', sans-serif;
        font-size: 13px;
        font-weight: 700;
        color: white;
        text-transform: uppercase;
    }

    .connect-wallet {
        border-radius: 35px;
        padding: 15px 30px!important;
        display: block!important;
        text-align: center;
        margin-top: 30px;
        background-color: #6c7386;
        color: white!important;
        font-family: 'Brown', sans-serif;
        font-size: 12px!important;
        font-weight: 700!important;
    }

    .connect-wallet:hover {
        background-color: #FF4ABD;
    }

    .btn-connected {
        background-color: var(--background-color-1)!important;
        cursor: default;
    }

    .disconnect-wallet:hover {
        color: var(--color-primary);
    }

    .nav-link i[class^="feather-"],
    .submenu i[class^="feather-"] {
        color: gray;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .nav-link {
            font-size: 11px!important;
            padding: 21px 5px!important;
        }
    }
</style>
