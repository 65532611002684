import { createStore } from 'vuex'

export const store = createStore({
  state: {
    authCheck: !window.localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER'),
    accountAddress: null,
    networkId: null
  },
  getters: {
    isConnected(state, getters) {
      return state.accountAddress && getters.isValidNetwork
    },
    isValidNetwork(state) {
      return state.networkId == process.env.VUE_APP_NETWORK_ID
    }
  },
  mutations: {
    SET_AUTH_CHECK(state, payload) {
      state.authCheck = payload
    },
    SET_ACCOUNT_ADDRESS(state, payload) {
      state.accountAddress = payload;
    },
    SET_NETWORK_ID(state, payload) {
      state.networkId = payload;
    }
  },
  actions: {},
  modules: {},
})
