<template>
    <layout>
        <!-- Start product details area -->
        <div v-if="loading" class="container text-center mt--100">
            <img :src="require('@/assets/images/loader.svg')" alt="Loading..." width="150">
        </div>
        <div v-else :class="`product-details-area rn-section-gapTop ${product.attributes.rarity.toLowerCase()}`">
            <div class="container">
                <div class="row g-5">
                    <!-- Product image area Start -->
                    <div class="col-lg-7 col-md-12 col-sm-12">
                        <div class="product-tab-wrapper rbt-sticky-top-adjust">
                            <div class="pd-tab-inner">
                                <div class="tab-content rn-pd-content" id="v-pills-tabContent">
                                    <div :class="[`tab-pane fade`, {'show active': 0 === activeTabIndex}]"
                                         :id="`v-pills-home-${0}`"
                                         role="tabpanel"
                                         :aria-labelledby="`v-pills-${0}-tab`"
                                         :key="`large-thumbnail-${0}`">
                                        <div class="rn-pd-thumbnail">
                                            <img :src="tokenImage" alt="Token Image">
                                        </div>
                                    </div>
                                    <div :class="[`tab-pane fade`, {'show active': 1 === activeTabIndex}]"
                                         :id="`v-pills-home-${1}`"
                                         role="tabpanel"
                                         :aria-labelledby="`v-pills-${1}-tab`"
                                         :key="`large-thumbnail-${1}`">
                                        <div :class="`position-relative rn-pd-thumbnail${1 === activeTabIndex ? ' bg-black' : ''}`">
                                            <button @click="downloadVideo" type="button" class="download-button" :disabled="downloading">
                                                <img v-if="downloading" :src="require('@/assets/images/loader.svg')" alt="Loading..." width="20">
                                                <i v-else class="feather-download"></i>
                                            </button>
                                            <video ref="tokenVideo" :src="tokenVideo" loop></video>
                                            <!-- <img :src="tokenImage" alt="Token Image"> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="nav rn-pd-nav rn-pd-rt-content nav-pills mt--10" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button :class="[`nav-link`, {'active': index === activeTabIndex}]"
                                            :id="`v-pills-${index}-tab`"
                                            type="button"
                                            role="tab"
                                            @click="activeTabIndex = Number(!activeTabIndex)"
                                            :aria-controls="`v-pills-home-${index}`"
                                            :disabled="activeTabIndex === index"
                                            :aria-selected="index === activeTabIndex ? 'true' : 'false'"
                                            v-for="(_, index) in 2"
                                            :key="`small-thumbnail-${index}`">
                                        <span class="rn-pd-sm-thumbnail">
                                            <i v-if="index === 0" class="feather-image"></i>
                                            <i v-else class="feather-film"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product image area end -->

                    <div class="col-lg-5 col-md-12 col-sm-12 mt_md--50 mt_sm--60">
                        <div class="rn-pd-content-area">
                            <h6 class="title-name">
                                #{{ product.tokenId }}
                            </h6>
                            <div class="pd-title-area">
                                <h4 class="title">
                                    {{ product.attributes.title.split(' - ')[0] }}
                                </h4>
                            </div>
                            <div class="rn-bid-details">
                                <div class="tab-wrapper-one">
                                    <div class="tab-content rn-bid-content" id="nav-tabContent">
                                        <div class="tab-pane fade active show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <div class="rn-pd-bd-wrapper">
                                                <div class="top-seller-inner-one pt--0">
                                                    <h6 @click="seeingOwners = !seeingOwners" :class="`name-title d-inline-block${product.owners.length > 0 ? ' pointer' : ''}`">
                                                        <template v-if="product.owners.length > 0">
                                                            {{ product.owners.length }}
                                                            owners

                                                            <button type="button" class="owners-arrow">
                                                                <i v-if="seeingOwners" class="feather-chevron-up"></i>
                                                                <i v-else class="feather-chevron-down"></i>
                                                            </button>
                                                        </template>
                                                        <template v-else>
                                                            No owners found for this token.
                                                        </template>
                                                    </h6>
                                                    <div ref="ownersWrapper" class="top-seller-wrapper">
                                                        <div ref="ownersContent" class="top-seller-content ml--0">
                                                            <a v-for="address in product.owners" :key="`token-owner-${address}`" :href="holderLink(address)" target="_blank">
                                                                <h6 class="name mb--10">
                                                                    {{ address }}
                                                                </h6>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="rn-pd-sm-property-wrapper">
                                                    <h6 class="pd-property-title">
                                                        Attributes
                                                    </h6>
                                                    <div class="property-wrapper">
                                                        <!-- single property -->
                                                        <div :class="`pd-property-inner${!value ? ' novalue' : ''}`" @click="filterAttribute(key, value)" v-for="(value, key) in filteredAttributes" :key="`attribute-${key}`">
                                                            <span class="type">
                                                                {{ key }}
                                                            </span>
                                                            <span class="color-white value">
                                                                {{ value || 'N/A' }}
                                                            </span>
                                                        </div>
                                                        <!-- single property End -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a :href="viewOnOpenSeaLink" class="btn btn-primary-alta view-on-opensea" target="_blank">
                                View on OpenSea
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End product details area -->
    </layout>
</template>

<script>
    import Layout from '@/components/layouts/Layout'
    import http from '@/services/httpService'
    import AppFunctions from '@/helpers/AppFunctions'

    export default {
        name: 'TokenSinglePage',
        components: {Layout},
        data() {
            return {
                AppFunctions,
                activeTabIndex: 0,
                loading: true,
                downloading: false,
                seeingOwners: false,
                product: {}
            }
        },
        computed: {
            filteredAttributes() {
                const { animated } = this.product.attributes
                const { artist } = this.product.attributes
                const { category } = this.product.attributes
                const { editionSize } = this.product.attributes
                const { rarity } = this.product.attributes
                const { variant } = this.product.attributes

                return {
                    animated,
                    artist,
                    category,
                    'edition size': editionSize,
                    rarity,
                    variant,
                }
            },
            tokenImage() {
                return `${process.env.VUE_APP_API_URL}/images/cards/${this.product.tokenId}.jpg`
            },
            tokenVideo() {
                return `${process.env.VUE_APP_API_URL}/videos/cards/${this.product.tokenId}-low.mp4`
            },
            viewOnOpenSeaLink() {
                return `https://opensea.io/assets/ethereum/${process.env.VUE_APP_CARD_CONTRACT_ADDRESS}/${this.product.tokenId}`
            }
        },
        watch: {
            '$route.params.id'(v) {
                if (v) {
                    this.getProduct()
                }
            },
            activeTabIndex(v) {
                if (v === 1) {
                    this.$refs.tokenVideo.play()
                } else {
                    this.$refs.tokenVideo.pause()
                }
            },
            seeingOwners(v) {
                if (v) {
                    this.$refs.ownersWrapper.style.height = `${this.$refs.ownersContent.clientHeight}px`
                } else {
                    this.$refs.ownersWrapper.style.height = '0px';
                }
            }
        },
        methods: {
            getProduct() {
                this.loading = true

                http.get(`/tokens/${this.$route.params.id}`)
                    .then(response => {
                        this.product = response.data
                    })
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            holderLink(address) {
                // const contractAddress = process.env.VUE_APP_CARD_CONTRACT_ADDRESS
                // const networkName = process.env.VUE_APP_NETWORK_NAME

                // return `https://${networkName == 'mainnet' ? '' : networkName + '.'}etherscan.io/token/${contractAddress}?a=${address}`
                return `https://opensea.io/${address}`
            },
            filterAttribute(group, value) {
                if (!value) {
                    return
                }

                if (group == 'edition size') {
                    group = 'editionSize'
                }

                window.sessionStorage.setItem('ATTRIBUTE_FILTER', JSON.stringify({ group, value }))
                this.$router.push('/gallery')
            },
            downloadVideo() {
                const url = this.tokenVideo.replace('-low.mp4', '.mp4')
                const fileName = `${this.product.tokenId}.mp4`

                this.downloading = true

                http.get(url, {
                    responseType: 'blob'
                })
                    .then((response) => {
                        AppFunctions.downloadFile(response.data, fileName)
                    })
                    .catch(err => {
                        console.error(err)
                    })
                    .finally(() => {
                        this.downloading = false
                    })
            }
        },
        mounted() {
            this.getProduct()
        }
    }
</script>

<style scoped>
    .product-tab-wrapper .rn-pd-content {
        flex-basis: 100%;
        text-align: center;
        padding: 0;
    }

    .property-wrapper .pd-property-inner {
        user-select: none;
        cursor: pointer;
    }

    .property-wrapper .pd-property-inner.novalue {
        cursor: default;
    }

    .property-wrapper .pd-property-inner.novalue .value {
        color: var(--color-body)!important;
    }

    .product-tab-wrapper .rn-pd-content img {
        width: 100%;
        max-width: 400px;
    }

    .product-tab-wrapper .rn-pd-content video {
        width: 100%;
        max-width: 562.64px;
    }

    .product-tab-wrapper .rn-pd-rt-content button {
        margin: 0;
        width: 50%;
    }

    .product-tab-wrapper .rn-pd-nav {
        flex-direction: initial;
        justify-content: space-between;
        flex-basis: 100%;
        margin: 0;
        padding: 0;
    }

    .product-tab-wrapper .pd-tab-inner {
        flex-direction: column;
    }

    .property-wrapper {
        text-transform: uppercase;
    }

    .top-seller-content h6 {
        font-size: 12px!important;
        font-weight: 400!important;
    }

    .feather-image,
    .feather-film {
        font-size: 22px;
    }

    .nav-pills .nav-link {
        color: var(--color-body);
    }

    .download-button {
        position: absolute;
        top: 17px;
        right: 17px;
        width: 40px;
        height: 40px;
        border-radius: 35px;
        cursor: pointer;
        z-index: 1;
        background-color: #000000;
    }

    .download-button:hover {
        background-color: var(--color-border);
    }

    .download-button:disabled {
        pointer-events: none;
    }

    .owners-arrow {
        display: inline-block;
        width: 25px;
        height: 25px;
        border-radius: 35px;
        border: 0;
        padding: 0;
    }

    .top-seller-wrapper {
        height: 0;
        overflow: hidden;
        transition: height 300ms;
    }

    /* Common */
    .product-details-area.common .nav-link.active .rn-pd-sm-thumbnail {
        background-color: #FFFFFF;
        border: 0;
    }

    .product-details-area.common .nav-pills .nav-link.active {
        color: black;
    }

    .product-details-area.common .property-wrapper .pd-property-inner {
        border-color: #FFFFFF!important;
    }

    .product-details-area.common .property-wrapper .pd-property-inner span.type {
        color: #FFFFFF!important;
    }

    .product-details-area.common .top-seller-wrapper .name:hover {
        color: #FFFFFF!important;
    }

    .product-details-area.common .view-on-opensea:hover {
        background: #FFFFFF!important;
        color: black!important;
    }

    .product-details-area.common .download-button:focus {
        border-color: #FFFFFF!important;
    }

    .product-details-area.common .owners-arrow {
        color: white;
    }

    /* Rare */
    .product-details-area.rare .nav-link.active .rn-pd-sm-thumbnail {
        background-color: #FFD700;
        border: 0;
    }

    .product-details-area.rare .nav-pills .nav-link.active {
        color: black;
    }

    .product-details-area.rare .property-wrapper .pd-property-inner {
        border-color: #FFD700!important;
    }

    .product-details-area.rare .property-wrapper .pd-property-inner span.type {
        color: #FFD700!important;
    }

    .product-details-area.rare .top-seller-wrapper .name:hover {
        color: #FFD700!important;
    }

    .product-details-area.rare .view-on-opensea:hover {
        background: #FFD700!important;
        color: black!important;
    }

    .product-details-area.rare .download-button:focus {
        border-color: #FFD700!important;
    }

    .product-details-area.rare .owners-arrow {
        color: #FFD700;
    }

    /* Legendary */
    .product-details-area.legendary .nav-link.active .rn-pd-sm-thumbnail {
        background-color: #00BF94;
        border: 0;
    }

    .product-details-area.legendary .nav-pills .nav-link.active {
        color: black;
    }

    .product-details-area.legendary .property-wrapper .pd-property-inner {
        border-color: #00BF94!important;
    }

    .product-details-area.legendary .property-wrapper .pd-property-inner span.type {
        color: #00BF94!important;
    }

    .product-details-area.legendary .top-seller-wrapper .name:hover {
        color: #00BF94!important;
    }

    .product-details-area.legendary .view-on-opensea:hover {
        background: #00BF94!important;
        color: black!important;
    }

    .product-details-area.legendary .download-button:focus {
        border-color: #00BF94!important;
    }

    .product-details-area.legendary .owners-arrow {
        color: #00BF94;
    }

    /* Mythic */
    .product-details-area.mythic .nav-link.active .rn-pd-sm-thumbnail {
        background-color: #FF4ABD;
        border: 0;
    }

    .product-details-area.mythic .nav-pills .nav-link.active {
        color: black;
    }

    .product-details-area.mythic .property-wrapper .pd-property-inner {
        border-color: #FF4ABD!important;
    }

    .product-details-area.mythic .property-wrapper .pd-property-inner span.type {
        color: #FF4ABD!important;
    }

    .product-details-area.mythic .top-seller-wrapper .name:hover {
        color: #FF4ABD!important;
    }

    .product-details-area.mythic .view-on-opensea:hover {
        background: #FF4ABD!important;
        color: black!important;
    }

    .product-details-area.mythic .download-button:focus {
        border-color: #FF4ABD!important;
    }

    .product-details-area.mythic .owners-arrow {
        color: #FF4ABD;
    }
</style>
